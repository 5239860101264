import { Typography, Box, Grid, Button, FormHelperText } from "@mui/material";
import { PageBreadCrum } from "components/authenticated/common/Breadcrum";
import Layout from "components/authenticated/common/Layout";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { changeValue, formValues, initForm, formErrors, isValid, validateForm } from "redux-services/slices/FormSlices";
import { FloatValidator, LengthValidator, PositiveValueValidator, useAddSiteMutation, useEditSiteMutation, useGetCustomerByFieldQuery, useGetSiteByUuidQuery } from "redux-services";
import { useNavigate, useParams } from "react-router-dom";
import { FormElement, Site } from "types";
import CancelView from "components/authenticated/common/CancelView";


const SiteEditor: React.FunctionComponent = () => {

    const { uuid, site_uuid } = useParams();

    const dispatch = useDispatch<AppDispatch>();

    const [pageTitle, setPageTitle] = useState('Add');

    const changeFormValue = (event: React.ChangeEvent<HTMLInputElement>) => dispatch(changeValue(event));

    const navigate = useNavigate();

    const { isLoading, data: customerData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    });

    const { data: siteData } = useGetSiteByUuidQuery(site_uuid,{
        skip: !site_uuid
    });


    const formElementData: FormElement[] = [
        {
            label: 'Site Id',
            attributes: {
                name: 'site_id',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'Site Name',
            attributes: {
                name: 'name',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'Type',
            attributes: {
                name: 'type',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'select',
                required: true
            },
            validators: [new LengthValidator(10, 'max')]
        },
        {
            label: 'Description',
            attributes: {
                name: 'Desc',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'textarea',
                required: false
            },
            validators: [new LengthValidator(255, 'max')]
        },
        {
            label: 'Latitude',
            attributes: {
                name: 'lat',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(100, 'max'), new FloatValidator()]
        },
        {
            label: 'Longitude',
            attributes: {
                name: 'lon',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(100, 'max'), new FloatValidator()]
        },
        {
            label: 'Leak Rate Low',
            attributes: {
                name: 'leak_rate_low',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'number',
                required: false
            },
            validators: [new PositiveValueValidator()]           
        },
        {
            label: 'Leak Rate Med',
            attributes: {
                name: 'leak_rate_med',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'number',
                required: false
            },
           validators: [new PositiveValueValidator()] 
        },
        {
            label: 'Leak Rate High',
            attributes: {
                name: 'leak_rate_high',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'number',
                required: false
            },
            validators: [new PositiveValueValidator()]
        },
        {
            label: 'Leak Total Low',
            attributes: {
                name: 'leak_total_low',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'number',
                required: false
            },
           validators: [new PositiveValueValidator()]
        },
        {
            label: 'Leak Total Med',
            attributes: {
                name: 'leak_total_med',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'number',
                required: false
            },
           validators: [new PositiveValueValidator()] 
        },
        {
            label: 'Leak Total High',
            attributes: {
                name: 'leak_total_high',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'number',
                required: false 
            },
           validators: [new PositiveValueValidator()]             
        },
    ];



    
    // first define the form structure to init to store
    const form = {
        validators: {
            ...formElementData.reduce((a, v) => ({ ...a, [v.attributes.name]: { 'required': v.attributes?.required, validators: v.validators } }), {})
        },
        values: {
            ...formElementData.reduce(
                (a, v) => ({ ...a, [v.attributes.name]: site_uuid && siteData ? siteData?.result[0][v.attributes.name as keyof Site]: v.attributes.type == 'number'? 0 :'' }), {})
        }
    }

    

    const siteEditorBreadcrum: PageBreadCrum[] = [
        {
            description: "Customer list",
            displayName: 'Customers',
            link: `/customers`
        },
        {
            description: "Customer Detail",
            displayName: customerData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Customer Site Detail",
            displayName: 'sites',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Edit Site",
            displayName: (site_uuid? "Edit": "Add") + " Site",
            link: `/customers/detail/${uuid}/site/` + (site_uuid? "edit/"+ site_uuid : "add")
        }
        
    ]

    useEffect(() => {
        dispatch(initForm(form))
        if(site_uuid){
            setPageTitle('Edit')
            dispatch(validateForm(SiteEditorFormValue))
        }
    }, [siteData])

    const SiteEditorFormValue = useSelector(formValues)

    const [addSite, result] = useAddSiteMutation();
    const [editSite, editResult] = useEditSiteMutation();


    const errors = useSelector(formErrors)

    const isFormValid = useSelector(isValid)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(validateForm(SiteEditorFormValue))
        if (isFormValid) {
            let siteFormData = {
                ...SiteEditorFormValue,
                customer: {
                    uuid
                },
                photo: ''
            }
            site_uuid? editSite({...siteFormData, uuid: site_uuid}): addSite(siteFormData) 
            
        }
    }

    if ((result.isSuccess && result.data && result.data.status_code === 200) || editResult.isSuccess && editResult.data && editResult.data.status_code === 200) {
        
         navigate(`/customers/detail/${uuid}/site/detail/${site_uuid ?? result?.data?.result[0].uuid}`);
    }


    return (
        <Layout isLoading={(result.isLoading || isLoading)} breadcrums={siteEditorBreadcrum}>
            <Box className="customer-list">
                <Box className="searchouterright">
                    <Box className="pageheading">
                        <Typography variant="h2">{pageTitle} Site</Typography>
                    </Box>
                </Box>
                <Box component="form" onSubmit={handleSubmit} className="customerform">

                    <Grid container spacing={3}>
                        <>
                            {formElementData.map((item, index) =>
                                <Grid key={index} item xs={12} md={6}>
                                    <Box className="formgroup">
                                        <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                        <TextField
                                            className={item.attributes.className}
                                            inputProps={item.attributes}
                                            variant="outlined"
                                            value={Object.keys(SiteEditorFormValue).length? SiteEditorFormValue[item.attributes.name]: ''}
                                        />
                                        <FormHelperText error={errors[item.attributes.name] ? true : false}>{errors[item.attributes.name]}</FormHelperText>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box className="btn-group">
                                    <CancelView actionUrl={`/customers/detail/${uuid}`} />
                                    <Button type="submit" className="btn customer-form-btn "> Submit</Button>
                                </Box>
                            </Grid>
                        </>
                    </Grid>
                </Box>
            </Box>
        </Layout>
    );
}

export default SiteEditor;