import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Anemometer } from 'types';

export interface CustomerDetailViewProps {
    anemometer: Anemometer | undefined;
}
const AnemometerDetailView: React.FunctionComponent<CustomerDetailViewProps> = (
    props: CustomerDetailViewProps
) => {
    const anemometer = props.anemometer;

    const anemometerData = [{
        label: 'Name',
        key: 'anemometer_id'
    },
    {
        label: 'Owner',
        key: 'owner'
    }, {
        label: 'EUI',
        key: 'eui'
    }, {
        label: 'Latitude',
        key: 'lat'
    }, {
        label: 'Longitude',
        key: 'lon'
    }, {
        label: 'App Key',
        key: 'app_key'
    },
    {
        label: 'Created At',
        key: 'insert_datetime'
    }
    ]

    if (anemometer) {
        return (
            <Box className='detailsbody'>
                <Grid container>
                    <>
                        {anemometerData.map((data, index) =>
                            <Grid item xs={6} md={4} key={index}>
                                <Box className='userdetailsinner'>
                                    <label>{data.label}</label>
                                    <Typography variant='subtitle1'>{anemometer[data.key as keyof Anemometer]}</Typography>
                                </Box>
                            </Grid>
                        )
                        }
                    </>
                </Grid>
            </Box>
        );
    } else {
        return <></>;
    }
};

export default AnemometerDetailView;
