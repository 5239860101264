import React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, Toolbar, IconButton, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logoImage from "assets/img/logo_nevadanano.png";
import loadingImage from "assets/img/loading-36.gif";

import { KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetLoader } from 'redux-services';
import { setAuthLogout } from 'redux-services/slices/AuthSlice';
import { MtUser } from 'types';



export interface ProfileMenuItem {
    key: string;
    eventHandler: React.MouseEventHandler<HTMLAnchorElement>;
    href: string;
}

export interface HeaderProps{
    isLoading?: boolean;
}


const Header: React.FunctionComponent<HeaderProps> = (_props: HeaderProps) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout: React.MouseEventHandler<HTMLAnchorElement> = (_event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(null);
        localStorage.clear();
        dispatch(setAuthLogout())
        navigate('/');

    };
    
    const userData: MtUser = JSON.parse(localStorage.user??'{}');
    
    const isLoading = useSelector(GetLoader);
    const menuItems: ProfileMenuItem[] = [
        {
            key: 'Logout',
            eventHandler: handleLogout,
            href: "#!"
        }];

    return (
        <AppBar position="static" className="topbar-appbar">
            <Container maxWidth="xl" className="topbar-container">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={logoImage} alt="Logo" />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {/* <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton> */}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >

                        </Menu>
                    </Box>
                    
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                    </Box>

                    <Box sx={{ flexGrow: 0 }} className="topbar-appbar-right">
                        <Tooltip title="">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Typography variant="h2" className="avatertext">{userData?.first_name[0]}</Typography>
                                <p className="profile-title">{userData?.first_name + " " + userData?.last_name}</p>                                
                                <KeyboardArrowDown />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {menuItems.map((item: ProfileMenuItem) => (
                                <MenuItem key={item.key} onClick={item.eventHandler} href={item.href}>
                                    <Typography textAlign="center">{item.key}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
                <Box className={`loader-outer ${isLoading ? 'addloader' : ''}` }>
                    <img src={loadingImage} />
                </Box>
            </Container>
        </AppBar>
    );
}

export default Header;