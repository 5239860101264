import React, { useEffect, useState } from "react";

import {
  Box,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Paper,
  Pagination,
  Stack
} from '@mui/material';

import { setMarkers, useGetSitesByCustomerUuidQuery } from "redux-services";
import { Link } from "react-router-dom";
import { ListViewState, Marker, Site } from "types";
import { CoreMap } from "../common/map/CoreMap";
import { useDispatch } from "react-redux";
import { AppDispatch } from "app/store";
import NoRecord from "../common/NoRecord";

export interface CustomerSiteListViewProps {
  uuid: string | undefined;
  email: string;
  searchValue: string | undefined;
}


export interface CustomerSiteListViewState extends ListViewState {

}

const CustomersSiteListView: React.FunctionComponent<
  CustomerSiteListViewProps
> = (props: CustomerSiteListViewProps) => {
  const initialState: CustomerSiteListViewState = {
    page: 1,
    perPage: 5,
    searchText: ""
  };

  const dispatch = useDispatch<AppDispatch>();
  const [customerSiteListState, setCustomerSiteListState] = useState<
    CustomerSiteListViewState | undefined
  >(initialState);

  const { isSuccess, data } = useGetSitesByCustomerUuidQuery({
    customer_uuid: props.uuid,
    page: customerSiteListState?.page ?? 1,
    per_page: customerSiteListState?.perPage ?? 5,
    searchText: props.searchValue
  });




  useEffect(() => {
    if (data && isSuccess) {
      const markers: Marker[] = data.result.map((site: Site) => {
        return {
          id: site.uuid,
          title: site.site_id ?? '',
          marker: {
            lat: site.lat ?? 0,
            lng: site.lon ?? 0
          }
        }
      });

      dispatch(setMarkers(markers));
    }

  }, [data]);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCustomerSiteListState({
      page: value,
      perPage: initialState.perPage,
      searchText: props.searchValue ? props.searchValue : ""
    });
  };


  return (
    <>
      { data?.result?.length ? (
        <TableContainer component={Paper} className="sitetable">
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>Site Name</TableCell>
                <TableCell rowSpan={2}>Last leak Detected</TableCell>
                <TableCell rowSpan={2}>Description</TableCell>
                <TableCell
                  sx={{ pt: 0.5, pb: 0.5 }}
                  className="text-center border-bottom0"
                >
                  Leak Rate
                </TableCell>
                <TableCell rowSpan={2}>Lat, Lon</TableCell>
              </TableRow>
              <TableRow className="tablerow">
                <TableCell sx={{ p: 0 }}>
                  <Table sx={{ minWidth: 300 }} className="innertable">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="border-bottom0"
                          style={{ maxWidth: 80, width: 80 }}
                          sx={{ pt: 0 }}
                        >
                          Low
                        </TableCell>
                        <TableCell
                          className="border-bottom0"
                          style={{ maxWidth: 80, width: 80 }}
                          sx={{ pt: 0 }}
                        >
                          Med
                        </TableCell>
                        <TableCell
                          className="border-bottom0"
                          style={{ maxWidth: 80, width: 80 }}
                          sx={{ pt: 0 }}
                        >
                          High
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data.per_page ? data.result : []).map((site: Site) => (
                <TableRow key={site.name}>
                  <TableCell>
                    <Link
                      className="site-link"
                      to={`/customers/detail/${props.uuid}/site/detail/${site.uuid}`}
                    >
                      {site.name}
                    </Link>{" "}
                  </TableCell>
                  <TableCell>{site.insert_datetime}</TableCell>
                  <TableCell>{site.Desc}</TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <Table sx={{ minWidth: 300 }} className="innertable">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="border-bottom0"
                            style={{ maxWidth: 80, width: 80 }}
                          >
                            {site.leak_rate_low}
                          </TableCell>
                          <TableCell
                            className="border-bottom0"
                            style={{ maxWidth: 80, width: 80 }}
                          >
                            {site.leak_rate_med}
                          </TableCell>
                          <TableCell
                            className="border-bottom0"
                            style={{ maxWidth: 80, width: 80 }}
                          >
                            {site.leak_rate_high}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableCell>
                  <TableCell>
                    {site.lat}, {site.lon}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7}>
                  <Box className="paginationouter">
                    <Stack spacing={2}>
                      {/* <Pagination count={10} color='primary' /> */}
                      <Pagination
                        page={data?.page ?? 1}
                        count={data?.total_pages ?? 1}
                        color="primary"
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : <NoRecord title="Sites" />}

      {data?.result?.length ?
        <Box className="sitemap">
          <CoreMap title="All Sites" />
        </Box>
        : null
      }
    </>
  );
};

export default CustomersSiteListView;
