import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, useParams } from 'react-router-dom';
import { CustomerListBreadcrum, PageBreadCrum } from 'components/authenticated/common/Breadcrum';
import Layout from 'components/authenticated/common/Layout';
import TabPanel from 'components/authenticated/common/TabPanel';
import { useGetEndpointByUuidQuery, useGetCustomerByFieldQuery, useGetSiteByUuidQuery, setMarkers, useGetEndpointBySectionUuidQuery, useGetEndpointBySiteIdQuery } from 'redux-services';
import GoogleMap from 'components/authenticated/customer/GoogleMap';
import { Marker } from 'types';
import EndpointDetailView from './EndpointDetailView';
import { CoreMap } from 'components/authenticated/common/map/CoreMap';
import ActionView from 'components/authenticated/common/ActionView';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const EndpoinTabView: React.FunctionComponent = () => {
    const [value, setValue] = React.useState(0);

    const { uuid, site_uuid, endpoint_uuid, section_uuid } = useParams();

    const dispatch = useDispatch<AppDispatch>();

    const { isLoading, data: customerData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    });

    const { data: siteData } = useGetSiteByUuidQuery(site_uuid);


    const { data } = useGetEndpointBySiteIdQuery({
        site_uuid,
        searchText: ''
    }, {
        skip: section_uuid ? true : false
    });

    const { isSuccess: succ, data: sectionEndponts } = useGetEndpointBySectionUuidQuery({
        section_uuid,
        searchText: ''
    }, {
        skip: !section_uuid
    });

    const pageData = section_uuid ? sectionEndponts : data;



    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { isSuccess, data: endpointData } = useGetEndpointByUuidQuery(endpoint_uuid);


    useEffect(() => {
        if (pageData && (isSuccess || succ)) {
            const markers: Marker[] = pageData ? pageData.result.map((endpoint: any) => {
                const endpointItem = section_uuid ? endpoint.endpoint_uuid : endpoint;
                return {
                    selected: endpointItem.uuid === endpointData?.result[0].uuid,
                    title: endpointItem.end_point_id,
                    marker: {
                        lat: endpointItem.lat ?? 0,
                        lng: endpointItem.lon ?? 0
                    }
                }
            }) : [];
            dispatch(setMarkers(markers));
        }
    }, [sectionEndponts, data, endpointData]);



    const getUrl = (type: string) => {
        return `/customers/detail/${uuid}/site/detail/${site_uuid}/endpoint/${type}/${endpoint_uuid}`
    }
    const customerDetailBreadcrum: PageBreadCrum[] = [
        CustomerListBreadcrum,
        {
            description: "Customer Detail",
            displayName: customerData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Site Detail",
            displayName: siteData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}`
        },
        {
            description: "endpoint Detail",
            displayName: endpointData?.result[0].end_point_id ?? '',
            link: getUrl('detail')
        }

    ]
    return (
        <>
            <Layout isLoading={isLoading} breadcrums={customerDetailBreadcrum}>
                <Box className='mainsection'>
                    <Box className='tabouter'>
                        <Box className='tabinnerbox' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label='basic tabs example'
                                className='tabitemouter'
                            >
                                <Tab label='Endpoint Details' {...a11yProps(0)} />
                            </Tabs>
                            <Box className="searchrighttab addcustomerrightpart">
                                {value == 0 && (
                                    <Box>
                                        <ActionView actionClick={getUrl('edit')} />
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <Box className='borderdiv'>

                            <TabPanel value={value} index={0}>
                                <EndpointDetailView endpoint={endpointData?.result[0]} />
                                <Box className="sitemap">
                                    <CoreMap title="Endpoints" />
                                </Box>
                            </TabPanel>
                        </Box>
                    </Box>
                </Box>
            </Layout>
        </>
    );
};

export default EndpoinTabView;
