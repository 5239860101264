import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CoreMap } from 'components/authenticated/common/map/CoreMap';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setMarkers, useGetSiteByUuidQuery, useGetSitesByCustomerUuidQuery } from 'redux-services';
import { Marker, Site } from 'types';

export interface SiteDetailViewProps {
  site: Site | undefined;
}
const SiteDetailView: React.FunctionComponent<SiteDetailViewProps> = (
  props: SiteDetailViewProps
) => {
  const site = props.site;

  const dispatch = useDispatch();
  const { site_uuid, uuid } = useParams();

  const { data } = useGetSitesByCustomerUuidQuery({
    customer_uuid: uuid,
  });

  const { isSuccess, data: siteData } = useGetSiteByUuidQuery(site_uuid);



  


  useEffect(() => {
    if(data  && isSuccess){
      const markers: Marker[] = data.result.map((siteItem: Site) => {
        return {
          selected: siteData?.result[0].uuid === siteItem.uuid, 
          title: siteItem.site_id ?? '',
          marker: {
            lat: siteItem.lat ?? 0,
            lng: siteItem.lon ?? 0
          }
        }
      });
    
      dispatch(setMarkers(markers));
    }
    
}, [data, siteData]);


  if (site) {
    return (
      <>
        <Box className="detailsbody">
          <Grid container>
            <Grid item xs={9}>
              <Grid container>

                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Site Name</label>
                    <Typography variant="subtitle1">
                      {site.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Leak Rate Low</label>
                    <Typography variant="subtitle1">
                      {site.leak_rate_low}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Leak Total Med</label>
                    <Typography variant="subtitle1">
                      {site.leak_total_med}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Latitute</label>
                    <Typography variant="subtitle1">
                      {site.lat}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Leak Rate Med</label>
                    <Typography variant="subtitle1">
                      {site.leak_rate_med}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Leak Total High</label>
                    <Typography variant="subtitle1">
                      {site.leak_total_high}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Longitude</label>
                    <Typography variant="subtitle1">
                      {site.lon}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Leak Rate High</label>
                    <Typography variant="subtitle1">
                      {site.leak_rate_high}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Description</label>
                    <Typography variant="subtitle1">
                      {site.Desc}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Type</label>
                    <Typography variant="subtitle1">
                      {site.type}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="userdetailsinner">
                    <label>Leak Total Low</label>
                    <Typography variant="subtitle1">
                      {site.leak_total_low}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>

        </Box>
        <Box className="sitemap">
          <CoreMap title="Endpoints"/>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

export default SiteDetailView;
