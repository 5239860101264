import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, Response, Anemometer } from "types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface AnemometerMeta extends PageMeta {
  site_uuid: string
}

export interface GetAnemometerBySiteIdRequest extends PageMeta {
  site_uuid: string | undefined;
  searchText: string | undefined;
}

export const AnemometerApi = createApi({
  reducerPath: "anemometerApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["AnemometerTag"],
  endpoints: (builder) => ({
    getAnemometerBySiteId: builder.query<Response<Anemometer[]>, GetAnemometerBySiteIdRequest>({
      query: (data: GetAnemometerBySiteIdRequest) => ({
        url: `/sites/${data.site_uuid}/anemometers`,
        method: "GET",
        params: {
          search: data.searchText ?? ''
        }
      }),
      providesTags: ["AnemometerTag"],
    }),
    getAnemometerByUuid: builder.query<Response<Anemometer[]>, string | undefined>({
      query: (anemometer_uuid: string | undefined) => ({
        url: `/anenometers`,
        method: "GET",
        params: {
          anemometer_uuid
        }
      }),
      providesTags: (response) =>
        response
          ? [...response.result.map(({ uuid }) => ({ type: 'AnemometerTag' as const, id: uuid })), 'AnemometerTag']
          : ['AnemometerTag'],
    }),
    addAnemometer: builder.mutation<Response<Anemometer[]>, Anemometer>(
      {
        query: (anemometer: Anemometer) => ({
          url: `/anenometers`,
          method: "POST",
          body: anemometer
        }),
        invalidatesTags: ["AnemometerTag"],
      }
    ),
    editAnemometer: builder.mutation<Response<Anemometer[]>, Anemometer>(
      {
        query: (anemometer: Anemometer) => ({
          url: `/anenometers/${anemometer.uuid}`,
          method: "PUT",
          body: anemometer
        }),
        invalidatesTags: (_result, _error, endpoint) => [{ type: 'AnemometerTag', id: endpoint.uuid }, 'AnemometerTag'],

      }
    ),
  }),
});

export const { 
  useGetAnemometerBySiteIdQuery, 
  useAddAnemometerMutation, 
  useGetAnemometerByUuidQuery,
  useEditAnemometerMutation
} = AnemometerApi;