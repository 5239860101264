import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import arrowImage from "assets/img/arrow.svg";




export interface PageBreadCrum {
    displayName: string;
    description: string;
    link: string;
}
export const CustomerListBreadcrum: PageBreadCrum  = {
    description: "Customer list",
    displayName: 'Customers',
    link: `/customers`
}

export const CustomerDetailBreadcrum = {}

export const SiteListBreadcrum = {}

export const SustomerDetailBreadcrum = {}
export interface BreadCrumProps {
    titles: PageBreadCrum[];
}

const Breadcrum: React.FunctionComponent<BreadCrumProps> = (props: BreadCrumProps) => {

    return (
        <Box className="breadcrum">
            <>
                <Typography key="0" textAlign="left">
                    <Link to="/">Dashboard</Link>
                </Typography>
                {props.titles?.map((title, index) =>
                    <Typography key={index} className="breadcrum-text">
                        <img src={arrowImage} alt="arrow" />
                        <Link  to={title.link}>{title.displayName}</Link>
                    </Typography>

                )}
            </>
        </Box>
    )
}
export default Breadcrum;