import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Marker} from "types";


export interface SectionState {
    markers: Marker[];
}

const initialState: SectionState = {
    markers: [],
};

const MapSlices = createSlice({
  name: "sectionSlices",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setMarkers: (state, action) => {
      state.markers = action.payload;
    },
  },
  
});

const { reducer, actions } = MapSlices;

export const { setMarkers } = actions;

export const getMarkers = (state: RootState) => state.map.markers;

export default reducer;