import { Typography, Box, Grid, Button, FormHelperText, SelectChangeEvent, Select, MenuItem } from "@mui/material";
import { CustomerListBreadcrum, PageBreadCrum } from "components/authenticated/common/Breadcrum";
import Layout from "components/authenticated/common/Layout";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { changeValue, formValues, initForm, formErrors, isValid, validateForm } from "redux-services/slices/FormSlices";
import { useAddEndpointMutation, useGetCustomerByFieldQuery, useGetSiteByUuidQuery, useGetApplicationQuery, useGetDeviceProfileQuery, useGetEndpointByUuidQuery, useEditEndpointMutation, LengthValidator, FloatValidator } from "redux-services";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Endpoint, FormElement, ListViewState } from "types";
import CancelView from "components/authenticated/common/CancelView";

export interface ApplicationViewState extends ListViewState {

}

const EndpointEditor: React.FunctionComponent = () => {
    const dispatch: AppDispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState('Add');
    const changeFormValue = (event: React.ChangeEvent<HTMLInputElement>) => dispatch(changeValue(event));
    const changeSelectValue = (event: SelectChangeEvent<string>) => dispatch(changeValue(event));
    const { uuid, site_uuid, endpoint_uuid } = useParams();

    const { data: endpointData } = useGetEndpointByUuidQuery(endpoint_uuid, { skip: !endpoint_uuid });

    const formElementData: FormElement[] = [
        {
            label: 'Endpoint Id',
            attributes: {
                name: 'end_point_id',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'Owner',
            attributes: {
                name: 'owner',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: false
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'Type',
            attributes: {
                name: 'type',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'Gas',
            attributes: {
                name: 'gas',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(10, 'max')]
        },
        {
            label: 'Latitude',
            attributes: {
                name: 'lat',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(100, 'max'), new FloatValidator()]
        },
        {
            label: 'Longitude',
            attributes: {
                name: 'lon',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(100, 'max'), new FloatValidator()]
        },
        {
            label: 'Height',
            attributes: {
                name: 'height_agl',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'number',
                required: true
            },
            validators: [new LengthValidator(20, 'max'), new FloatValidator()]
        },

        {
            label: 'EUI',
            nonEditable: true,
            attributes: {
                name: 'eui',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(16, 'max')]
        },
        {
            label: 'App Key',
            attributes: {
                name: 'app_key',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(40, 'max')]
        },
        {
            label: 'Application Name',
            nonEditable: true,
            attributes: {
                name: 'application_name',
                className: 'form-control app-select',
                onSelectChange: changeSelectValue,
                type: 'select',
                required: true
            }
        },
        {
            label: 'Device Profile Name',
            nonEditable: true,
            attributes: {
                name: 'device_profile_name',
                className: 'form-control app-select',
                onSelectChange: changeSelectValue,
                type: 'select',
                required: true
            }
        }
    ];


    // first define the form structure to init to store
    const form = {
        validators: {
            ...formElementData.reduce((a, v) => ({ ...a, [v.attributes.name]: (endpoint_uuid && v.nonEditable) ? {} : { 'required': v.attributes.required, validators: v.validators } }), {})
        },
        values: {
            ...formElementData.reduce(
                (a, v) => ({ ...a, [v.attributes.name]: site_uuid && endpointData ? endpointData?.result[0][v.attributes.name as keyof Endpoint] : v.attributes.type == 'number' ? 0 : '' }), {})
        }
    }

    useEffect(() => {
        dispatch(initForm(form))
        if (endpoint_uuid) {
            setPageTitle('Edit');
            dispatch(validateForm(EndpointEditorFormValue))
        }
    }, [endpointData])



    const EndpointEditorFormValue = useSelector(formValues)

    const [addEndpoint, result] = useAddEndpointMutation();
    const [editEndpoint, editResult] = useEditEndpointMutation();


    const errors = useSelector(formErrors)

    const isFormValid = useSelector(isValid)

    const { data: customerData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    });

    const initialState: ApplicationViewState = {
        page: 1,
        perPage: 28,
        searchText: ""
    }

    const [applicationState, setApplicationState] = useState<ApplicationViewState | undefined>(initialState);
    // const [deviceProfileState, setDeviceProfileState] = useState<ApplicationViewState | undefined>(initialState);

    const { data: applicationData } = useGetApplicationQuery({
        per_page: applicationState?.perPage ?? 28,
        page: applicationState?.page ?? 1,
        searchText: applicationState?.searchText ?? ""
    });

    const { data: deviceProfileData } = useGetDeviceProfileQuery({
        per_page: applicationState?.perPage ?? 28,
        page: applicationState?.page ?? 1,
        searchText: applicationState?.searchText ?? ""
    })

    const { data: siteData } = useGetSiteByUuidQuery(site_uuid);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(validateForm(EndpointEditorFormValue))
        if (isFormValid) {
            let endpointDataModel = {
                ...EndpointEditorFormValue,
                site_uuid: site_uuid,
                org_name: customerData?.result[0].name,
            }
            endpoint_uuid ? editEndpoint({ ...endpointDataModel, uuid: endpoint_uuid }) : addEndpoint(endpointDataModel);
        }
    }

    if (result.isSuccess && result.data && result.data.status_code === 200 || editResult.isSuccess && editResult.data && editResult.data.status_code === 200) {
        navigate(`/customers/detail/${uuid}/site/detail/${site_uuid}/endpoint/detail/${endpoint_uuid ?? result.data?.result[0].uuid}`);
    }

    const endPointEditorBreadcrumbs: PageBreadCrum[] = [
        CustomerListBreadcrum,
        {
            description: "Customer Detail",
            displayName: customerData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Site Detail",
            displayName: siteData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}`
        },
        {
            description: (endpoint_uuid ? "Edit" : "Add") + " Endpoint",
            displayName: (endpoint_uuid ? "Edit" : "Add") + " Endpoint",
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}/endpoint` + (endpoint_uuid ? `edit/${endpoint_uuid}` : "add")
        }

    ]

    return (
        <Layout isLoading={result.isLoading} breadcrums={endPointEditorBreadcrumbs}>
            <Box className="customer-list">
                <Box className="searchouterright">
                    <Box className="pageheading">
                        <Typography variant="h2">{pageTitle} Endpoint</Typography>
                    </Box>
                </Box>
                <Box component="form" onSubmit={handleSubmit} className="customerform">

                    <Grid container spacing={3}>
                        <>
                            {formElementData.map((item, index) =>
                                !item.nonEditable || !endpoint_uuid ?
                                    <Grid key={index} item xs={item.xs ?? 6}>
                                        <Box className="formgroup">
                                            {item.attributes.type === 'select' ? (
                                                <>
                                                    {item.attributes.name === 'application_name' && (
                                                        <>
                                                            <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                                            <Select
                                                                displayEmpty
                                                                labelId={item.label}
                                                                id="demo-simple-select-application-name"
                                                                label="Select Application"
                                                                value={Object.keys(EndpointEditorFormValue).length ? EndpointEditorFormValue[item.attributes.name] : ''}
                                                                name={item.attributes.name}
                                                                className={item.attributes.className}
                                                                onChange={item.attributes.onSelectChange}
                                                            >
                                                                <MenuItem disabled value="">
                                                                    <em>Select Application</em>
                                                                </MenuItem>
                                                                {applicationData && applicationData.result.map((aitem, index) =>
                                                                    <MenuItem key={index} value={aitem.name}>{aitem.name}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </>
                                                    )}

                                                    {item.attributes.name === 'device_profile_name' && (
                                                        <>
                                                            <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                                            <Select
                                                                displayEmpty
                                                                labelId={item.label}
                                                                id="demo-simple-select-device-profile-name"
                                                                label="Select Device Profile"
                                                                value={Object.keys(EndpointEditorFormValue).length ? EndpointEditorFormValue[item.attributes.name] : ''}
                                                                name={item.attributes.name}
                                                                className={item.attributes.className}
                                                                onChange={item.attributes.onSelectChange}
                                                            >
                                                                <MenuItem disabled value="">
                                                                    <em>Select Device Profile</em>
                                                                </MenuItem>
                                                                {deviceProfileData && deviceProfileData.result.map((ditem, index) =>
                                                                    <MenuItem key={index} value={ditem.name}>{ditem.name}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </>
                                                    )}

                                                </>
                                            ) : (
                                                <><label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                                    <TextField
                                                        type={item.attributes.type}
                                                        id={item.attributes.name}
                                                        variant="outlined"
                                                        value={Object.keys(EndpointEditorFormValue).length ? EndpointEditorFormValue[item.attributes.name] : ''}
                                                        className={item.attributes.className}
                                                        name={item.attributes.name}
                                                        onChange={item.attributes.onChange}
                                                    />
                                                </>
                                            )}
                                            <FormHelperText error={errors[item.attributes.name] ? true : false}>{errors[item.attributes.name]}</FormHelperText>
                                        </Box>
                                    </Grid>
                                    : null)}
                            <Grid item xs={12}>
                                <Box className="btn-group cancelBtn">
                                    <CancelView actionUrl={`/customers/detail/${uuid}/site/detail/${site_uuid}`} />
                                    <Button type="submit" className="btn customer-form-btn "> Submit</Button>
                                </Box>
                            </Grid>
                        </>
                    </Grid>
                </Box>
            </Box>
        </Layout >
    );
}

export default EndpointEditor;