import { Button } from "@mui/material";
import React from "react";


export interface CancelProps {
    actionUrl?: string;
}
const CancelView: React.FunctionComponent<CancelProps> = (props: CancelProps) => {
    return (
        <>
            <Button href={props.actionUrl} className="btn customer-form-btn btn-outlined"> Cancel</Button>
        </>
    )
}

export default CancelView;