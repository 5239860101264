import React, { ReactElement } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "components/public/login/login";
import CustomerListView from "components/authenticated/customer/CustomerListView";
import CustomerTabView from "components/authenticated/customer/customer-detail/CustomerTabView";
import SiteTabview from "components/authenticated/site/SiteTabView";
import CustomerEditor from "components/authenticated/customer/customer-editor/CustomerEditor";
import SiteEditor from "components/authenticated/site/site-editor/SiteEditor";
import AnemometerEditor from "components/authenticated/anemometer/anemometer-editor/AnemometerEditor";
import EndpointEditor from "components/authenticated/endpoint/endpoint-editor/EndpointEditor";
import AnemometerTabView from "components/authenticated/anemometer/anemometer-detail/AnemometerTabView";
import SectionEditor from "components/authenticated/section/section-editor/SectionEditor";
import EndpoinTabView from "components/authenticated/endpoint/endpoint-detail/EndpointTabView";
import SectionTabView from "components/authenticated/section/section-detail/SectionTabView";
import GatewayEditor from "components/authenticated/gateway/gateway-editor/GatewayEditor";



export const RequiredAuth: React.FunctionComponent<{children: ReactElement}> = ({children})=>{

  
  let location = useLocation();
  

  const userAccessToken = localStorage.userAccessToken; 

  if (!userAccessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

const App: React.FunctionComponent = (_props) => {
  

  return (
    <div className="App">
      <Routes>
       
        <Route path="/login" element={<Login />} />

        <Route path="customers" element={<RequiredAuth><CustomerListView /></RequiredAuth>} />
        <Route path="customers/add" element={<RequiredAuth><CustomerEditor /></RequiredAuth>} />
        <Route path="customers/edit/:uuid" element={<RequiredAuth><CustomerEditor /></RequiredAuth>} />

        <Route path="customers/detail/:uuid" element={<RequiredAuth><CustomerTabView /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/detail/:site_uuid" element={<RequiredAuth><SiteTabview /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/add" element={<RequiredAuth><SiteEditor /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/edit/:site_uuid" element={<RequiredAuth><SiteEditor /></RequiredAuth>} />

        <Route path="customers/detail/:uuid/site/detail/:site_uuid/endpoint/add" element={<RequiredAuth><EndpointEditor /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/detail/:site_uuid/endpoint/edit/:endpoint_uuid" element={<RequiredAuth><EndpointEditor /></RequiredAuth>} />

        <Route path="customers/detail/:uuid/site/detail/:site_uuid/anemometer/add" element={<RequiredAuth><AnemometerEditor /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/detail/:site_uuid/anemometer/edit/:anemometer_uuid" element={<RequiredAuth><AnemometerEditor /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/detail/:site_uuid/section/add" element={<RequiredAuth><SectionEditor /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/detail/:site_uuid/section/edit/:section_uuid" element={<RequiredAuth><SectionEditor /></RequiredAuth>} />

        <Route path="customers/detail/:uuid/site/detail/:site_uuid/anemometer/detail/:anemometer_uuid" element={<RequiredAuth><AnemometerTabView /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/detail/:site_uuid/section/detail/:section_uuid" element={<RequiredAuth><SectionTabView /></RequiredAuth>} />
        
        <Route path="customers/detail/:uuid/site/detail/:site_uuid/endpoint/detail/:endpoint_uuid" element={<RequiredAuth><EndpoinTabView /></RequiredAuth>} />
        <Route path="customers/detail/:uuid/site/detail/:site_uuid/gateway/add" element={<RequiredAuth><GatewayEditor /></RequiredAuth>} />
        <Route path="*" element={<Navigate to={localStorage.userAccessToken ? "/customers":"/login"} />} />

      </Routes>
    </div>
  );
};

export default App;
