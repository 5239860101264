import { createSlice } from '@reduxjs/toolkit'
import { AlertColor } from '@mui/material'
import { RootState } from 'app/store';

export interface Alerts {
  type: AlertColor;
  message: string;
  timeout?: number;
}

export interface AlertState {
  alerts: Alerts[];
  isLoading: boolean;
}

const initialState: AlertState = {
  alerts: [],
  isLoading: false,
}

export const AlertBoxSlice = createSlice({
  name: 'alert-box',
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.alerts.push(action.payload)
    },
    setLoader: (state) => {
      state.isLoading = !state.isLoading; 
    },
    removeAlert: (state, action) => {
      state.alerts.splice(action.payload, 1);
    },
    removeAllAlert: (state) => {
      state.alerts = [];
    }
  },
})


const { reducer, actions } = AlertBoxSlice;

export const { setAlert, setLoader, removeAlert, removeAllAlert } = actions;

export default reducer;

export const GetAlert = (state:RootState) => state.alertbox.alerts;
export const GetLoader = (state:RootState) => state.alertbox.isLoading;

