import { createApi } from "@reduxjs/toolkit/query/react";
import { Customer, PageMeta, Response } from "types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

const CUSTOMER_TAG = "customer";
export interface GetCustomerByFieldRequest {
  field: string;
  value: any;
}
export const CustomerApi = createApi({
  reducerPath: "customerAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["CustomerTag"],
  endpoints: (builder) => ({
    
    getCustomers: builder.query<Response<Customer[]>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/customers`,
        method: "GET",
        params: {
          limit: pageData.per_page,
          page: pageData.page,
          order: "desc",
          search: pageData.searchText,
        },
      }),
      providesTags: ["CustomerTag"]
      
    }),
    
    getCustomerByField: builder.query<Response<Customer[]>, GetCustomerByFieldRequest>(
      {
        query: (data: GetCustomerByFieldRequest) => ({
          url: `/customers`,
          method: "GET",
          params: {
            [data.field]: data.value,
          },
        }),
        providesTags: (response) =>
        response
          ? [...response.result.map(({ uuid }) => ({ type: 'CustomerTag' as const, id: uuid })), 'CustomerTag']
          : ['CustomerTag'],
      }

    ),
    
    addCustomer: builder.mutation<Response<Customer[]>, Customer>(
      {
        query: (customer: Customer) => ({
          url: `/customers`,
          method: "POST",
          body: customer
        }),
        invalidatesTags: ["CustomerTag"],
      }
    ),

    editCustomer: builder.mutation<Response<Customer[]>, Customer>(
      {
        query: (customer: Customer) => ({
          url: `/customers/${customer.uuid}`,
          method: "PUT",
          body: customer
        }),
        invalidatesTags: (_result, _error, customer) => [{ type: 'CustomerTag', id: customer.uuid }, 'CustomerTag'],
      }
    ),
  }),
});

export const { useGetCustomersQuery, useGetCustomerByFieldQuery, useAddCustomerMutation, useEditCustomerMutation } = CustomerApi;
