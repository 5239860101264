import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, Response, Site } from "types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export const SiteApi = createApi({
  reducerPath: "siteApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["SiteTag"],
  endpoints: (builder) => ({
    getSitesByCustomerUuid: builder.query<Response<Site[]>, PageMeta | undefined>({
      query: (pageData: PageMeta) => ({
        url: `/sites`,
        method: "GET",
        params: {
          limit: pageData.per_page,
          page: pageData.page,
          customer_uuid: pageData.customer_uuid,
          search: pageData.searchText,
        },
      }),
      providesTags: ["SiteTag"]
    }),
    getSiteByUuid: builder.query<Response<Site[]>, string | undefined>({
      query: (site_uuid: string | undefined) => ({
        url: `/sites`,
        method: "GET",
        params: {
          site_uuid: site_uuid,
        },
      }),
      providesTags: (response) =>
      response
        ? [...response.result.map(({ uuid }) => ({ type: 'SiteTag' as const, id: uuid })), 'SiteTag']
        : ['SiteTag'],
      
    }),
    getSiteDetailTabView: builder.query<Response<Site[]>, string | undefined>({
      query: (name: string | undefined) => ({
        url: `/sites`,
        method: "GET",
        params: {
          name,
        },
      }),
      
    }),
    addSite: builder.mutation<Response<Site[]>, Site>({
      query: (site: Site) => ({
        url: `/sites`,
        method: "POST",
        body: site
      }),
      invalidatesTags: (_result, _error, site) => ['SiteTag'],
    }),

    editSite: builder.mutation<Response<Site[]>, Site>({
      query: (site: Site) => ({
        url: `/sites/${site.uuid}`,
        method: "PUT",
        body: site
      }),
      invalidatesTags: (_result, _error, site) => [{ type: 'SiteTag', id: site.uuid },'SiteTag'],

    }),
  }),
});

export const { useGetSitesByCustomerUuidQuery, useGetSiteByUuidQuery, useGetSiteDetailTabViewQuery, useAddSiteMutation, useEditSiteMutation } = SiteApi;
