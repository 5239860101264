import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, Response, Section, SectionAnemometer, SectionEndpoint, uuid } from "types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface SectionMeta extends PageMeta {
  site_uuid?: string,
  section_uuid?: string,
  anemometer_uuid?: string,
}

export interface MapEndpointRequest {
  section_uuid: string | undefined;
  endpoint_uuid: string[];
}

export interface MapAnemometerRequest {
  section_uuid: uuid;
  anemometer_uuid: uuid;
}

export interface DeleteMappingRequest {
  type: string;
  uuid: string | string[];
}


export const SectionApi = createApi({
  reducerPath: "sectionApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["SectionTag", "SectionAnemometerTag", "SectionEndpointTag"],
  endpoints: (builder) => ({
    getSectionBySiteId: builder.query<Response<Section[]>, SectionMeta>({
      query: (data: SectionMeta) => ({
        url: `/sites/${data.site_uuid}/sections`,
        method: "GET",
        params: {
          limit: data.per_page,
          page: data.page,
          order: "desc",
          search: data.searchText
        }
      }),
      providesTags: ["SectionTag"],
    }),

    getSectionByUuid: builder.query<Response<Section[]>, string | undefined>({
      query: (section_uuid: string | undefined) => ({
        url: `/sections`,
        method: "GET",
        params: {
          section_uuid
        }
      }),
      providesTags: (response) =>
        response
          ? [...response.result.map(({ uuid }) => ({ type: 'SectionTag' as const, id: uuid })), 'SectionTag']
          : ['SectionTag'],
    }),
    getAnemometerBySectionUuid: builder.query<Response<SectionAnemometer[]>, SectionMeta>({
      query: (sectionData: SectionMeta) => ({
        url: `/sectionanenometer`,
        method: "GET",
        params: sectionData
      }),
      providesTags: ["SectionAnemometerTag"],
    }),

    getEndpointBySectionUuid: builder.query<Response<SectionEndpoint[]>, SectionMeta>({
      query: (sectionData: SectionMeta) => ({
        url: `/sectionendpoint`,
        method: "GET",
        params: sectionData
      }),
      providesTags: ["SectionEndpointTag"],
    }),

    mapEndpointToSectionUuid: builder.mutation<Response<SectionAnemometer[]>, MapEndpointRequest>({
      query: (sectionData: MapEndpointRequest) => ({
        url: `/sectionendpoint`,
        method: "POST",
        body: sectionData
      }),
      invalidatesTags: ["SectionEndpointTag"],
    }),

    mapAnemometerToSectionUuid: builder.mutation<Response<any>, MapAnemometerRequest>({
      query: (sectionData: MapAnemometerRequest) => ({
        url: `/sectionanenometer`,
        method: "POST",
        body: sectionData
      }),
      invalidatesTags: ["SectionAnemometerTag"],
    }),

    deleteMappingToSectionUuid: builder.mutation<Response<any>, DeleteMappingRequest>({
      query: (sectionData: DeleteMappingRequest) => ({
        url: `/section${sectionData.type}`,
        method: "DELETE",
        body: {
          mapping_uuid: sectionData.uuid
        },
        params: {
          mapping_uuid: sectionData.type === 'anenometer'? sectionData.uuid:''
        }
      }),
      invalidatesTags: ["SectionAnemometerTag","SectionEndpointTag"],
    }),

    addSection: builder.mutation<Response<Section[]>, Section>(
      {
        query: (section: Section) => ({
          url: `/sections`,
          method: "POST",
          body: section
        }),
        invalidatesTags: ["SectionTag"],
      }
    ),
    editSection: builder.mutation<Response<Section[]>, Section>(
      {
        query: (section: Section) => ({
          url: `/sections/${section.uuid}`,
          method: "PUT",
          body: section
        }),
        invalidatesTags: (_result, _error, section) => [{ type: 'SectionTag', id: section.uuid }, 'SectionTag'],
      }
    ),
  }),
});

export const {
  useGetSectionBySiteIdQuery,
  useAddSectionMutation,
  useGetSectionByUuidQuery,
  useGetEndpointBySectionUuidQuery,
  useGetAnemometerBySectionUuidQuery,
  useMapAnemometerToSectionUuidMutation,
  useMapEndpointToSectionUuidMutation,
  useEditSectionMutation,
  useDeleteMappingToSectionUuidMutation,

} = SectionApi;