import { Grid, Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { CustomerListBreadcrum, PageBreadCrum } from "components/authenticated/common/Breadcrum";
import { useGetCustomersQuery } from "redux-services";
import CustomerView from "./CustomerView";
import Layout from "components/authenticated/common/Layout";
import MuiSearchBox from "components/authenticated/common/MuiSearchBox";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ListViewState } from "types";
import NoRecord from "../common/NoRecord";

export interface CustomerListViewState extends ListViewState {

}


const CustomerListView: React.FunctionComponent = (props) => {


    const dispatch = useDispatch();


    const initialState: CustomerListViewState = {
        page: 1,
        perPage: 28,
        searchText: ""
    }

    const [customerState, setCustomerState] = useState<CustomerListViewState | undefined>(initialState);

    const { isSuccess, isLoading, data } = useGetCustomersQuery({
        per_page: customerState?.perPage ?? 28,
        page: customerState?.page ?? 1,
        searchText: customerState?.searchText ?? ""
    });



    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setCustomerState({
            page: value,
            perPage: initialState.perPage,
            searchText: customerState ? customerState.searchText : ""
        });
    }

    const handleSearchChange = (value: string) => {
        setCustomerState({
            page: 1,
            perPage: customerState ? customerState.perPage : 28,
            searchText: value
        });
    }

    const customerDetailBreadcrum: PageBreadCrum[] = [CustomerListBreadcrum];

    return (
        <>
            <Layout isLoading={isLoading} breadcrums={customerDetailBreadcrum}>
                <Box className="customer-list">
                    <Grid className="searchouterright">
                        <Box className="pageheading">
                            <Typography variant="h3">Customer List</Typography>
                        </Box>
                        <Box className="addcustomerrightpart">
                            <MuiSearchBox searchHandler={handleSearchChange} />
                            <Grid className="search">
                                <Link className="btn customer-form-btn " to="/customers/add"> Add Customer </Link>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container>
                        {isSuccess && data?.result?.length > 0 ? data?.result?.map((customer, index) => (
                            <CustomerView
                                key={`cell-${index}`}
                                customer={customer}
                            />
                        )) : <NoRecord title="Customers" />}
                    </Grid>
                    {data && data?.result?.length && (
                        <Grid className="pagination-grid ">
                            <Pagination page={data?.page ?? 1} count={data?.total_pages ?? 1} color="primary" onChange={handlePageChange} />
                        </Grid>
                    )}

                </Box>
            </Layout>
        </>
    );
}

export default CustomerListView;