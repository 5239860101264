import { createSlice } from "@reduxjs/toolkit";
import {  MtUser} from "types";


export interface AuthState {
    user: MtUser | null;
    isAuthenticated: boolean;
    userAccessToken: string;
}

const initialState: AuthState = {
    user: null,
    isAuthenticated: false,
    userAccessToken: '',
};

const AuthSlices = createSlice({
  name: "authSlices",
  initialState,
  reducers: {
    setAuth: (state) => {
      state.isAuthenticated = true;
      state.userAccessToken = localStorage.userAccessToken;
      state.user = JSON.parse(localStorage.user);
    },
    setAuthLogout: (state) => {
        state.isAuthenticated = false;
        state.userAccessToken = '';
        state.user = null;
        localStorage.clear();
      },
  },
  
});

const { reducer, actions } = AuthSlices;

export const { setAuth, setAuthLogout } = actions;

export default reducer;