import React, { useState } from "react";
import { Box, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { Gateway, ListViewState } from "types";
import { useGetGatewayQuery } from "redux-services";
import NoRecord from "../common/NoRecord";
import { useParams } from "react-router-dom";


export interface GatewayListViewProps {
    searchText?: string;
}
export interface GatewayListViewState extends ListViewState {

}

export const GatewayListView: React.FunctionComponent<GatewayListViewProps> = (props: GatewayListViewProps) => {

    const { uuid, site_uuid } = useParams()

    const initialState: GatewayListViewState = {
        page: 1,
        perPage: 5,
    };

    const [gatewayListState, setGatewayListState] = useState<
        GatewayListViewState | undefined
    >(initialState);

    const { data } = useGetGatewayQuery({
        searchText: props.searchText,
        site_uuid: site_uuid,
        page: gatewayListState?.page,
        per_page: gatewayListState?.perPage,
    });

    const handlePageChange = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setGatewayListState({
            page: value,
            perPage: initialState.perPage,
            searchText: props.searchText ? props.searchText : ""

        });
    };


    return (
        <>
            {data && data?.result?.length ? (
                <TableContainer component={Paper} className="sitetable">
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell rowSpan={2}>Euid</TableCell>
                                <TableCell rowSpan={2}>Name</TableCell>
                                <TableCell rowSpan={2}>Oragnization Name</TableCell>
                                <TableCell rowSpan={2}>Create At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data?.per_page ? data.result : []).map((gateway: Gateway, index) => (
                                <TableRow key={index}>
                                    <TableCell>{gateway.euid}</TableCell>
                                    <TableCell>{gateway.name}</TableCell>
                                    <TableCell>{gateway.organization_name}</TableCell>
                                    <TableCell>{gateway.insert_datetime}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Box className="paginationouter">
                                        <Stack spacing={2}>
                                            <Pagination
                                                page={data?.page ?? 1}
                                                count={data?.total_pages ?? 1}
                                                color="primary"
                                                onChange={handlePageChange}
                                            />
                                        </Stack>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            ) : <NoRecord title="Gateways" />}
        </>
    )
}