import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

export interface ActionViewProps {
    actionClick?: string
}

const ActionView: React.FunctionComponent<ActionViewProps> = (props: ActionViewProps | undefined) => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleCloseActionMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    return (

        <Box className='customeselect'>
            <Tooltip title="">
                <IconButton onClick={handleOpenActionMenu} sx={{ p: 0 }}>
                    <p className="profile-title">Action</p>
                    <KeyboardArrowDown />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-actionbar"
                className='menu-dropdown-list'
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseActionMenu}
            >

                <Link to={props?.actionClick ?? "!#"}>
                    <MenuItem>
                        <Typography textAlign="center">
                            Edit
                        </Typography>
                    </MenuItem>
                </Link>

            </Menu>
        </Box >
    )
}

export default ActionView;
