import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CoreMap } from 'components/authenticated/common/map/CoreMap';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setMarkers, useGetSectionBySiteIdQuery, useGetSectionByUuidQuery } from 'redux-services';
import { Marker, Section } from 'types';

export interface SectionDetailViewProps {
    section: Section | undefined;
}
const SectionDetailView: React.FunctionComponent<SectionDetailViewProps> = (
    _props: SectionDetailViewProps
) => {

    const { site_uuid, section_uuid } = useParams();
    const dispatch = useDispatch();
    const { isSuccess, data: sectionDetail } = useGetSectionByUuidQuery(section_uuid);


    const { data } = useGetSectionBySiteIdQuery({
        site_uuid,
        searchText: '',
    });

   

    useEffect(() => {
        if(data && (isSuccess)){
            const markers: Marker[] = data.result.map((section: Section) => {
                return {
                    selected: section.uuid === sectionDetail?.result[0].uuid,
                    title : section.section_id ?? '',
                    marker: {
                    lat: section.lat ?? 0,
                    lng: section.lon ?? 0
                    }
                }
            });
            dispatch(setMarkers(markers));
        }    
    }, [data, sectionDetail]);


    const sectionData = [
        {
            label: 'Name',
            key: 'section_id'
        },
        {
            label: 'Type',
            key: 'type'
        }, {
            label: 'Latitude',
            key: 'lat'
        }, {
            label: 'Longitude',
            key: 'lon'
        }, {
            label: 'Description',
            key: 'Desc'
        },
        {
            label: 'Created At',
            key: 'insert_datetime'
        }
    ]

    if (sectionDetail?.result.length) {
        return (<>
            <Box className='detailsbody'>
                <Grid container>
                    <>
                        {sectionData.map((data, index) =>
                            <Grid item xs={6} md={4} key={index}>
                                <Box className='userdetailsinner'>
                                    <label>{data.label}</label>
                                    <Typography variant='subtitle1'>{sectionDetail.result[0][data.key as keyof Omit<Section, "site_uuid">] }</Typography>
                                </Box>
                            </Grid>
                        )
                        }
                    </>
                </Grid>
            </Box>
             <Box className="sitemap">
                 <CoreMap  />
            </Box>
            </>
        );
    } else {
        return <></>;
    }
};

export default SectionDetailView;
