import { Typography, Box, Grid, Button, FormHelperText } from "@mui/material";
import { CustomerListBreadcrum, PageBreadCrum } from "components/authenticated/common/Breadcrum";
import Layout from "components/authenticated/common/Layout";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { changeValue, formValues, initForm, formErrors, isValid, validateForm } from "redux-services/slices/FormSlices";
import { FloatValidator, LengthValidator, useAddSectionMutation, useEditSectionMutation, useGetCustomerByFieldQuery, useGetSectionByUuidQuery, useGetSiteByUuidQuery } from "redux-services";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FormElement, Section } from "types";
import CancelView from "components/authenticated/common/CancelView";


const SectionEditor: React.FunctionComponent = () => {

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { uuid, site_uuid, section_uuid } = useParams();

    const [pageTitle, setPageTitle] = useState('Add');
    const changeFormValue = (event: React.ChangeEvent<HTMLInputElement>) => dispatch(changeValue(event));

    const formElementData: FormElement[] = [
        {
            label: 'Section Name',
            attributes: {
                name: 'section_id',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]

        },
        {
            label: 'Type',
            attributes: {
                name: 'type',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },   
        {
            label: 'Latitude',
            attributes: {
                name: 'lat',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new FloatValidator()]
        },

        {
            label: 'Longitude',
            attributes: {
                name: 'lon',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new FloatValidator()]
        },
        {
            label: 'Description',
            xs: 12,
            attributes: {
                name: 'Desc',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'textarea',
                required: true
            },
            validators: [new LengthValidator(255, 'max')]
        },  
        
    ];

    const { data: SectionData } = useGetSectionByUuidQuery(section_uuid,{skip: !section_uuid});


    // first define the form structure to init to store
    const form = {
        validators: {
            ...formElementData.reduce((a, v) => ({ ...a, [v.attributes.name]: { 'required': v.attributes.required, validators: v.validators } }), {})
        },
        values: {
            ...formElementData.reduce(
                (a, v) => ({ ...a, [v.attributes.name]: uuid && SectionData ? SectionData?.result[0][v.attributes.name as keyof Section]: v.attributes.type == 'number'? 0 :'' }), {})
        }
    }

    useEffect(() => {
        dispatch(initForm(form))
        if(SectionData){
            setPageTitle('Edit');
            dispatch(validateForm(SectionEditorFormValue))
        }
    }, [SectionData])


   
    const SectionEditorFormValue = useSelector(formValues)

    const [addSection, result] = useAddSectionMutation();
    const [editSection, editResult] = useEditSectionMutation();


    const errors = useSelector(formErrors)

    const isFormValid = useSelector(isValid)

    const { data: customerData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    });

    const { data: siteData } = useGetSiteByUuidQuery(site_uuid);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(validateForm(SectionEditorFormValue))
        if (isFormValid) {
            let SectionFormData = {
                ...SectionEditorFormValue,
                site_uuid: {
                    uuid: site_uuid
                },
                photo: ''
            }
            section_uuid? editSection({...SectionFormData, uuid: section_uuid}) :addSection(SectionFormData);
        }
    }

    if(result.isSuccess && result.data && result.data.status_code === 200 || editResult.isSuccess && editResult.data && editResult.data.status_code === 200) {
        navigate(`/customers/detail/${uuid}/site/detail/${site_uuid}/section/detail/${section_uuid??result?.data?.result[0].uuid}`);
    }

    const SectionEditorBreadcrumbs: PageBreadCrum[] = [
        CustomerListBreadcrum,
        {
            description: "Customer Detail",
            displayName: customerData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Site Detail",
            displayName: siteData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}`
        },
        {
            description: section_uuid?"Edit":"Add" + " Section",
            displayName: section_uuid?"Edit":"Add" + " Section",
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}/section/`+ section_uuid?"edit/"+section_uuid:'add'
        }

    ]

    return (
        <Layout isLoading={result.isLoading} breadcrums={SectionEditorBreadcrumbs}>
            <Box className="customer-list">
                <Box className="searchouterright">
                    <Box className="pageheading">
                        <Typography variant="h2">{pageTitle} Section</Typography>
                    </Box>
                </Box>
                <Box component="form" onSubmit={handleSubmit} className="customerform">

                    <Grid container spacing={3}>
                        <>
                            {formElementData.map((item, index) =>
                                <Grid key={index} item xs={item.xs ?? 12} md={6}>
                                    <Box className="formgroup">
                                        <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                        <TextField
                                            type={item.attributes.type}
                                            id={item.attributes.name}
                                            variant="outlined"
                                            className="form-control {item.className}"
                                            name={item.attributes.name}
                                            value={Object.keys(SectionEditorFormValue).length ? SectionEditorFormValue[item.attributes.name] : ''}
                                            onChange={item.attributes.onChange}
                                        />
                                        <FormHelperText error={errors[item.attributes.name] ? true : false}>{errors[item.attributes.name]}</FormHelperText>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box className="btn-group cancelBtn">
                                    <CancelView actionUrl={`/customers/detail/${uuid}/site/detail/${site_uuid}`} />
                                    <Button type="submit" className="btn customer-form-btn "> Submit</Button>
                                </Box>
                            </Grid>
                        </>
                    </Grid>
                </Box>
            </Box>
        </Layout>
    );
}

export default SectionEditor;