import React, { ReactElement } from "react";
import AlertBox from "./AlertBox";
import Breadcrum, { PageBreadCrum } from "./Breadcrum";
import Footer from "./Footer";
import Header from "./Header";

export interface LayoutProps{
    breadcrums: PageBreadCrum[]
    children: ReactElement,
    isLoading?: boolean
}
const  Layout: React.FunctionComponent<LayoutProps> = (props: LayoutProps) => {
    
    return ( 
        <>
        <Header isLoading={props.isLoading} />
        <Breadcrum titles={props.breadcrums} />
        <AlertBox type="success" message="Success Alert Box" />
        {props.children}
        <Footer />     
        </>
     );
}

export default Layout;