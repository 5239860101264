import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, Response, Endpoint, Application, DeviceProfile } from "types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface EndPointMeta extends PageMeta {
  Endpoint_uuid: string
}

export interface GetSitesByIdRequest extends PageMeta {
  site_uuid: string | undefined;
  searchText: string | undefined;
}

export const EndpointApi = createApi({
  reducerPath: "endpointApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["EndpointTag"],
  endpoints: (builder) => ({
    getEndpointBySiteId: builder.query<Response<Endpoint[]>, GetSitesByIdRequest>({
      query: (data: GetSitesByIdRequest) => ({
        url: `/sites/${data.site_uuid}/endpoints`,
        method: "GET",
        params: {
          limit: data.per_page,
          page: data.page,
          order: "desc",
          search: data.searchText
        }

      }),
      providesTags: ["EndpointTag"]
    }),
    getEndpointByUuid: builder.query<Response<Endpoint[]>, string | undefined>({
      query: (endpoint_uuid: string | undefined) => ({
        url: `/endpoints`,
        method: "GET",
        params: {
          endpoint_uuid
        }
      }),
      providesTags: (response) =>
        response
          ? [...response.result.map(({ uuid }) => ({ type: 'EndpointTag' as const, id: uuid })), 'EndpointTag']
          : ['EndpointTag'],
    }),
    getApplication: builder.query<Response<Application[]>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/application`,
        method: "GET",
        params: {
          limit: pageData.per_page,
          page: pageData.page,
          order: "desc",
          search: pageData.searchText,
        },
      }),
    }),
    getDeviceProfile: builder.query<Response<DeviceProfile[]>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/deviceprofile`,
        method: "GET",
        params: {
          limit: pageData.per_page,
          page: pageData.page,
          order: "desc",
          search: pageData.searchText,
        },
      }),
    }),
    addEndpoint: builder.mutation<Response<Endpoint[]>, Endpoint>({
      query: (endpoint: Endpoint) => ({
        url: `/endpoints`,
        method: "POST",
        body: endpoint
      }),
      invalidatesTags: ['EndpointTag'],
    }),
    editEndpoint: builder.mutation<Response<Endpoint[]>, Endpoint>({
      query: (endpoint: Endpoint) => ({
        url: `/endpoints/${endpoint.uuid}`,
        method: "PUT",
        body: endpoint
      }),
      invalidatesTags: (_result, _error, endpoint) => [{ type: 'EndpointTag', id: endpoint.uuid }, 'EndpointTag'],
    }),
  }),
});

export const { useGetEndpointBySiteIdQuery, useAddEndpointMutation, useGetEndpointByUuidQuery, useGetApplicationQuery, useGetDeviceProfileQuery, useEditEndpointMutation } = EndpointApi;
