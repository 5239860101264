import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useGetCustomerByFieldQuery } from 'redux-services';
import CustomerDetailView from './CustomerDetailView';

import { Link, useParams } from 'react-router-dom';
import { CustomerListBreadcrum, PageBreadCrum } from 'components/authenticated/common/Breadcrum';
import Layout from 'components/authenticated/common/Layout';
import MuiSearchBox from 'components/authenticated/common/MuiSearchBox';
import { ListViewState } from 'types';
import TabPanel from 'components/authenticated/common/TabPanel';


import CustomersSiteListView from 'components/authenticated/site/SiteListView';
import ActionView from 'components/authenticated/common/ActionView';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface CustomerTabViewState extends ListViewState {

}

const CustomerTabView: React.FunctionComponent = () => {
  const [value, setValue] = React.useState(0);

  const initialState: CustomerTabViewState = {
    searchText: ""
  }

  const [customerTabState, setCustomerTabState] = useState<CustomerTabViewState | undefined>(initialState);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  const handleSearchChange = (searchValue: string) => {
    setCustomerTabState({
      searchText: searchValue
    })
  }


  const { uuid } = useParams();


  const { data } = useGetCustomerByFieldQuery({
    field: 'customer_uuid',
    value: uuid
  })

  const customerDetailBreadcrum: PageBreadCrum[] = [
    CustomerListBreadcrum,
    {
      description: "Customer Detail",
      displayName: data?.result[0].name ?? '',
      link: `/customers/detail/${uuid}`
    }]


  return (
    <>
      <Layout isLoading={false} breadcrums={customerDetailBreadcrum}>
        <Box className='mainsection'>
          <Box className='tabouter'>
            <Box className='tabinnerbox' sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
                className='tabitemouter'
              >

                <Tab label='Details' {...a11yProps(0)} />
                <Tab label='Sites' {...a11yProps(1)} />


              </Tabs>
              <Box className="searchrighttab addcustomerrightpart">
                {value == 1 && (
                  <>
                    <MuiSearchBox searchHandler={handleSearchChange} />
                    <Grid className="search">
                      <Link className="btn customer-form-btn " to={`/customers/detail/${uuid}/site/add`}> Add Site </Link>
                    </Grid>

                  </>
                )}
              </Box>
              {value == 0 && (

                <Box>
                  <ActionView actionClick={`/customers/edit/${uuid}`} />
                </Box>
              )}
            </Box>

            <Box className='borderdiv'>
              <TabPanel value={value} index={0}>
                <CustomerDetailView customer={data?.result[0]} />
              </TabPanel>
              <TabPanel value={value} index={1} >
                <CustomersSiteListView searchValue={customerTabState?.searchText} email={data?.result[0].email ?? ""} uuid={data?.result[0].uuid ?? undefined} />
                <Box className='sitemap'>
                  {/* <GoogleMap /> */}
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default CustomerTabView;
