import { Typography, Box, Grid, Button, FormHelperText } from "@mui/material";
import { CustomerListBreadcrum, PageBreadCrum } from "components/authenticated/common/Breadcrum";
import Layout from "components/authenticated/common/Layout";
import React from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { changeValue, formValues, initForm, formErrors, isValid, validateForm } from "redux-services/slices/FormSlices";
import { useEffect } from "react";
import { FloatValidator, LengthValidator, useAddGatewayMutation, useGetCustomerByFieldQuery, useGetSiteByUuidQuery } from "redux-services";
import { useNavigate, useParams } from "react-router-dom";
import { FormElement } from "types";
import { Link } from "react-router-dom";


const GatewayEditor: React.FunctionComponent = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const changeFormValue = (event: React.ChangeEvent<HTMLInputElement>) => dispatch(changeValue(event));

    const formElementData: FormElement[] = [
        {
            label: 'EUID',
            attributes: {
                name: 'euid',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(40, 'max')]

        },
        {
            label: 'Name',
            attributes: {
                name: 'name',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },   
        {
            label: 'Organization Name',
            attributes: {
                name: 'organization_name',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'Altitude',
            attributes: {
                name: 'altitude',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
        },
        {
            label: 'Latitude',
            attributes: {
                name: 'latitude',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new FloatValidator()]

        },
        {
            label: 'Longitude',
            attributes: {
                name: 'longitude',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new FloatValidator()]

        },       
        {
            label: 'Description',
            xs: 12,
            attributes: {
                name: 'description',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'textarea',
                required: true
            },
            validators: [new LengthValidator(255, 'max')]
        },
        
    ];


    // first define the form structure to init to store
    const form = {
        validators: {
            ...formElementData.reduce((a, v) => ({ ...a, [v.attributes.name]: { 'required': v.attributes.required, validators: v.validators } }), {})
        }
    }

    useEffect(() => {
        dispatch(initForm(form))
    }, [])


    const { uuid, site_uuid } = useParams();
   
    const GatewayEditorFormValue = useSelector(formValues)

    const [addGateway, result] = useAddGatewayMutation();

    const errors = useSelector(formErrors)

    const isFormValid = useSelector(isValid)

    const { data: customerData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    });
    const { data: siteData } = useGetSiteByUuidQuery(site_uuid);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(validateForm(GatewayEditorFormValue))
        if (isFormValid) {
            let GatewayData = {
                ...GatewayEditorFormValue, 
                site_uuid: site_uuid,
                source: "UNKNOWN",
                accuracy: "0"
            }
            addGateway(GatewayData);
        }
    }

    if(result.isSuccess && result.data && result.data.status_code === 200) {
        navigate(`/customers/detail/${uuid}/site/detail/${site_uuid}`);
    }

    const GatewayEditorBreadcrumbs: PageBreadCrum[] = [
        CustomerListBreadcrum,
        {
            description: "Customer Detail",
            displayName: customerData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Site Detail",
            displayName: siteData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}`
        },
        {
            description: "Add Gateway",
            displayName: "Add Gateway",
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}/gateway/add`
        }

    ]
    return (
        <Layout isLoading={result.isLoading} breadcrums={GatewayEditorBreadcrumbs}>
            <Box className="customer-list">
                <Box className="searchouterright">
                    <Box className="pageheading">
                        <Typography variant="h2">Add Gateway</Typography>
                    </Box>
                </Box>
                <Box component="form" onSubmit={handleSubmit} className="customerform">

                    <Grid container spacing={3}>
                        <>
                            {formElementData.map((item, index) =>
                                <Grid key={index} item xs={item.xs ?? 12} md={6}>
                                    <Box className="formgroup">
                                        <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                        <TextField
                                            type={item.attributes.type}
                                            id={item.attributes.name}
                                            variant="outlined"
                                            className="form-control {item.className}"
                                            name={item.attributes.name}
                                            onChange={item.attributes.onChange}
                                        />
                                        <FormHelperText error={errors[item.attributes.name] ? true : false}>{errors[item.attributes.name]}</FormHelperText>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box className="btn-group cancelBtn">
                                    <Link className="btn customer-form-btn btn-outlined" to={`/customers/detail/${uuid}/site/detail/${site_uuid}`}>Cancel</Link>
                                    <Button type="submit" className="btn customer-form-btn "> Submit</Button>
                                </Box>
                            </Grid>
                        </>
                    </Grid>
                </Box>
            </Box>
        </Layout>
    );
}

export default GatewayEditor;