import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import { CustomerListBreadcrum, PageBreadCrum } from 'components/authenticated/common/Breadcrum';
import Layout from 'components/authenticated/common/Layout';
import TabPanel from 'components/authenticated/common/TabPanel';
import { useGetSectionByUuidQuery, useGetCustomerByFieldQuery, useGetSiteByUuidQuery, useGetSectionBySiteIdQuery, setMarkers, useDeleteMappingToSectionUuidMutation, setSelectedAnemometer, setSelectedEndpoint } from 'redux-services';
import SectionDetailView from './SectionDetailView';
import GoogleMap from 'components/authenticated/customer/GoogleMap';
import { Marker, Section } from 'types';
import EndPointListView from 'components/authenticated/endpoint/EndpointListView';
import { AnemometerListView } from 'components/authenticated/anemometer/AnemometerListView';
import MuiSearchBox from 'components/authenticated/common/MuiSearchBox';
import MapSectionDialogBox, { MapSectionDialogBoxProps } from '../MapSectionDialogBox';
import ActionView from 'components/authenticated/common/ActionView';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const SectionTabView: React.FunctionComponent = () => {

    const [value, setValue] = useState(0);

    const [endpointSearchText, setEndpointSearchText] = useState('');
    const [anemometerSearchText, setAnemometerSearchText] = useState('');
    const [isAnemometer, setIsAnemometer] = useState(false);
    const [isEndpoint, setIsEndpoint] = useState(false);


    const { uuid, site_uuid, section_uuid } = useParams();
    const dispatch = useDispatch();

    const { data: customerData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    });

    const { data: siteData } = useGetSiteByUuidQuery(site_uuid);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { data: SectionData } = useGetSectionByUuidQuery(section_uuid);

    const [ openDialog, setOpenDialog] = useState(false);
   
   
    const customerDetailBreadcrum: PageBreadCrum[] = [
        CustomerListBreadcrum,
        {
            description: "Customer Detail",
            displayName: customerData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Site Detail",
            displayName: siteData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}`
        },
        {
            description: "Section Detail",
            displayName: SectionData?.result[0].section_id ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}/section/detail/${section_uuid}`
        }

    ]

    const handleEndPointSearchChange = (endPointSearchText: string) => {
        setEndpointSearchText(endPointSearchText);
    }

    const handleAnemometerSearchChange = (anemometerSearchTxt: string) => {
        setAnemometerSearchText(anemometerSearchTxt)
    }
    const anemometerCheckedData = useSelector((state: RootState) => state.anemomenter.selectedAnemometer)
    const endpointCheckedData = useSelector((state: RootState) => state.endpoint.selectedEndpoint)

    const [ deleteMapping, deleteMappingResult] = useDeleteMappingToSectionUuidMutation();
    
    const closeDialogHandler = () => {
        setOpenDialog(false);
        setIsAnemometer(false);
        setIsEndpoint(false);
    }
    
    const openDialogHandler = (val: 'anenometer' | 'endpoint') => {
        
        if((val === 'anenometer' && anemometerCheckedData.length) || ( val === 'endpoint' && endpointCheckedData.length)) {
            deleteMapping({
                type: val,
                uuid: val === 'anenometer'? anemometerCheckedData[0]: endpointCheckedData
            });
            if(val === 'anenometer') {
                dispatch(setSelectedAnemometer([]));
            } else {
                dispatch(setSelectedEndpoint([]));
            }

            closeDialogHandler();
        } else {
            setOpenDialog(true);
            if(val === 'anenometer') {
                setIsAnemometer(true);
            } else {
                setIsEndpoint(true);
            }
        }
        
    }
   
    const dialogboxProps: MapSectionDialogBoxProps = {
        title: isEndpoint ? 'Map Endpoint' : 'Map Anemometer',
        onClose: closeDialogHandler,
        open: openDialog,
        isAnemometer,
        isEndpoint,
    }

    return (
        <>
            <Layout breadcrums={customerDetailBreadcrum}>
                <Box className='mainsection'>

                    <MapSectionDialogBox {...dialogboxProps}/>
                    <Box className='tabouter'>
                        <Box className='tabinnerbox' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label='basic tabs example'
                                className='tabitemouter'
                            >
                                <Tab label='Section Details' {...a11yProps(0)} />
                                <Tab label='Endpoints' {...a11yProps(1)} />
                                <Tab label='Anemometers' {...a11yProps(2)} />
                            </Tabs>
                            <Box className="searchrighttab addcustomerrightpart">
                                {value === 0 && (
                                <ActionView actionClick={`/customers/detail/${uuid}/site/detail/${site_uuid}/section/edit/${section_uuid}`}/>

                                )

                                }
                                {value == 1 && (
                                    <>
                                        <MuiSearchBox searchHandler={handleEndPointSearchChange} />
                                        <Grid className="search">
                                            <Button className="btn customer-form-btn " onClick={()=> openDialogHandler('endpoint')}> {endpointCheckedData.length ? 'Unmap': 'Map'} Endpoint </Button>
                                        </Grid>
                                    </>
                                )}
                                {value == 2 && (
                                    <>
                                        <MuiSearchBox placeholder="Search" searchHandler={handleAnemometerSearchChange} />
                                        <Grid className="search">
                                            <Button className="btn customer-form-btn" onClick={() => openDialogHandler('anenometer')} > {anemometerCheckedData.length ? 'Unmap': 'Map'} Anemometer </Button>
                                        </Grid>
                                    </>
                                )}

                            </Box>
                        </Box>
                        <Box className='borderdiv'>

                            <TabPanel value={value} index={0}>
                                <SectionDetailView section={SectionData?.result[0]} />
                            </TabPanel>
                            <TabPanel value={value} index={1} >
                                <EndPointListView isSection={true} searchText={endpointSearchText} />
                            </TabPanel>
                            <TabPanel value={value} index={2} >
                                <AnemometerListView isSection={true} searchText={anemometerSearchText} />
                            </TabPanel>

                        </Box>
                    </Box>
                   
                </Box>

            </Layout>
        </>
    );
};

export default SectionTabView;
