import { createApi } from "@reduxjs/toolkit/query/react";
import { MtUser, Response } from "types";
import { axiosBaseQuery } from "./AxiosBaseQuery";


export interface LoginRequest {
    email: string,
    password: string,

}

export interface LoginResponse extends MtUser {

}
export const LoginApi = createApi({
    reducerPath: "loginAPI",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["LoginTag"],
    endpoints: (builder) => ({
        doLogin: builder.mutation<Response<LoginResponse>, LoginRequest>(
            {
                query: (mtuser: LoginRequest) => ({
                    url: `/user/login`,
                    method: "POST",
                    body: mtuser
                }),
                invalidatesTags: ["LoginTag"],
                transformResponse: (response: Response<LoginResponse>, _meta, _arg) => {
                    if (response.status_code == 200) {
                        localStorage.setItem("userAccessToken", response.result.api_key);
                        localStorage.setItem("user", JSON.stringify(response.result));
                    }
                    return response;

                }
            }
        )
    })
})

export const { useDoLoginMutation } = LoginApi;