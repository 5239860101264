import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useGetAnemometerBySiteIdQuery } from "redux-services/apis/AnemometerApi";
import { setMarkers, setSelectedAnemometer, useGetAnemometerBySectionUuidQuery } from "redux-services";
import { ListViewState, Marker } from "types";
import { CoreMap } from "../common/map/CoreMap";
import { useDispatch, useSelector } from "react-redux";
import NoRecord from "../common/NoRecord";
import { RootState } from "app/store";


export interface AnemometerListViewProps {
  searchText?: string;
  isSection?: boolean;
}

export interface AnemometerListViewState extends ListViewState { }

export const AnemometerListView: React.FunctionComponent<AnemometerListViewProps> = (props: AnemometerListViewProps) => {

  const { uuid, site_uuid, section_uuid } = useParams()
  const initialState: AnemometerListViewState = {
    page: 1,
    perPage: 10,
    searchText: ""
  }
  const [anemomterListState, setAnemomterListState] = useState<AnemometerListViewState>(initialState);

  const checkedData = useSelector((state: RootState) => state.anemomenter.selectedAnemometer)
  const dispatch = useDispatch();

  const { isSuccess, data: anemometers } = useGetAnemometerBySiteIdQuery({
    site_uuid,
    page: anemomterListState.page,
    per_page: anemomterListState.perPage,
    searchText: props.searchText
  }, {
    skip: section_uuid ? true : false
  });

  const { isSuccess: succ, data: sectionAnemometers } = useGetAnemometerBySectionUuidQuery({
    section_uuid,
    page: anemomterListState.page,
    per_page: anemomterListState.perPage,
    searchText: props.searchText
  }, {
    skip: !section_uuid
  });

  const pageData = section_uuid ? sectionAnemometers : anemometers;

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setAnemomterListState({
      page: value,
      perPage: initialState.perPage,
    });
  }


  useEffect(() => {
    if (pageData && (isSuccess || succ)) {
      const markers: Marker[] = pageData.result.map((anemometer: any) => {
        const anemometerItem = section_uuid ? anemometer.anemometer_uuid : anemometer;
        return {
          title: anemometerItem.anemometer_id,
          marker: {
            lat: anemometerItem.lat ?? 0,
            lng: anemometerItem.lon ?? 0
          }
        }
      });
      dispatch(setMarkers(markers));
    }
  }, [anemometers, sectionAnemometers]);

  const handleCheckChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      dispatch(setSelectedAnemometer([...checkedData, e.target.name]));
    } else {
      dispatch(setSelectedAnemometer(checkedData.filter((selected) => selected !== e.target.name)))
    }

  }

  const handleParent = () => {
    if (pageData?.result?.length === checkedData.length) {
      dispatch(setSelectedAnemometer([]));
    } else {

      let listItems: string[] = [];
      pageData?.result.forEach((anemometer) => {
        listItems.push(anemometer.uuid ?? '');
      })
      dispatch(setSelectedAnemometer(listItems));
    }


  }
  const allChecked = pageData?.result?.length === checkedData.length;
  return (
    <>
      {pageData?.result?.length ? (
        <TableContainer component={Paper} className="sitetable">
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>

                <TableCell rowSpan={2}>
                  {section_uuid &&
                    <Checkbox
                      title="Select all"
                      checked={allChecked}
                      indeterminate={checkedData.length && !allChecked ? true : false}
                      onChange={handleParent}
                    />
                  }Anemometer Name</TableCell>
                <TableCell rowSpan={2}>Last Data Date</TableCell>
                <TableCell rowSpan={2}>Lat, Lon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(pageData?.result ? pageData.result : []).map((anemometer: any) => (
                <TableRow key={anemometer.uuid}>

                  <TableCell>
                    {
                      section_uuid && <Checkbox title="Select" checked={checkedData.includes(anemometer.uuid)} name={anemometer.uuid} onChange={handleCheckChange} />
                    }
                    <Link
                      className="site-link"
                      to={`/customers/detail/${uuid}/site/detail/${site_uuid}/anemometer/detail/${section_uuid ? anemometer.anemometer_uuid.uuid : anemometer.uuid}`}
                    >
                      {section_uuid ? anemometer.anemometer_uuid.anemometer_id : anemometer.anemometer_id}
                    </Link>{" "}
                  </TableCell>
                  <TableCell>{section_uuid ? anemometer.anemometer_uuid.insert_datetime : anemometer.insert_datetime}</TableCell>
                  <TableCell>{section_uuid ? anemometer.anemometer_uuid.lat : anemometer.lat}, {section_uuid ? anemometer.anemometer_uuid.lon : anemometer.lon}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7}>
                  <Box className="paginationouter">
                    <Stack spacing={2}>
                      {/* <Pagination count={10} color='primary' /> */}
                      <Pagination
                        page={pageData?.page ?? 1}
                        count={pageData?.total_pages ?? 1}
                        color="primary"
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : <NoRecord title="Anemometers" />}
      {pageData?.result?.length ? <Box className="sitemap">
        <CoreMap title="Site's Anemometer" />
      </Box>
        : null}
    </>
  )
}