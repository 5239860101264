import { createSlice } from "@reduxjs/toolkit";
import { EndpointApi } from "redux-services/apis";
import { Endpoint } from "types";


export interface EndpointState {
  endpointList: Endpoint[];
  selectedEndpoint: string[];
}

const initialState: EndpointState = {
  endpointList: [],
  selectedEndpoint: []
};

const EndPointSlices = createSlice({
  name: "EndPointSlices",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedEndpoint: (state, action) => {
      state.selectedEndpoint = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
        EndpointApi.endpoints.getEndpointBySiteId.matchFulfilled,
      (state, { payload }) => {
        state.endpointList = state.endpointList.concat(payload.result);
      }
    );
  },
});

const { reducer, actions } = EndPointSlices;

export const { setSelectedEndpoint } = actions;

export default reducer;
