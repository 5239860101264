import * as React from 'react';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, styled, TextField } from '@mui/material';
import {
    useGetAnemometerBySiteIdQuery,
    useGetEndpointBySiteIdQuery,
    useMapAnemometerToSectionUuidMutation,
    useMapEndpointToSectionUuidMutation,
} from 'redux-services';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { Anemometer, Endpoint } from 'types';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export interface MapSectionDialogBoxProps {
    title: string;
    onClose: () => void;
    open: boolean;
    isAnemometer?: boolean;
    isEndpoint?: boolean;
}

export interface OptionData {
    name: string | undefined;
    uuid: string | undefined;
}

const MapSectionDialogBox: React.FunctionComponent<MapSectionDialogBoxProps> = (props: MapSectionDialogBoxProps) => {

    const { isAnemometer, isEndpoint } = props;
    const initDta: OptionData[] = [];
    const [searchText, setSearchText] = React.useState('');
    const [autocompleteData, setAutocompleteData] = React.useState(initDta);


    const { site_uuid, section_uuid} = useParams();

    const { data, isLoading } = useGetAnemometerBySiteIdQuery({
        searchText: searchText,
        site_uuid: site_uuid
    }, {
        skip: !isAnemometer
    });

    const { data: endpoints, isLoading: endPointLoading } = useGetEndpointBySiteIdQuery({
        searchText: searchText,
        site_uuid: site_uuid
    }, {
        skip: !isEndpoint
    });

    const [mapAnemometerToSectionUuid, anemometerResult] = useMapAnemometerToSectionUuidMutation();

    const [mapEndpointToSectionUuid, endpointResult] = useMapEndpointToSectionUuidMutation();

    const handleMap = ((item: Anemometer | Endpoint) => {
        return {
            uuid: item.uuid,
            name: isAnemometer ? (item as Anemometer).anemometer_id : (item as Endpoint).end_point_id
        }
    })
    const options = isAnemometer ? data?.result.map(handleMap) ?? [] : endpoints?.result.map(handleMap) ?? [];

    const debouncedSearch = debounce(async (search: string) => {
        setSearchText(search);
    }, 500);

    async function handleSearchChange(_event: React.SyntheticEvent, value: string, _reason: string) {
        debouncedSearch(value);
    }

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        if (isAnemometer && autocompleteData.length) {
            mapAnemometerToSectionUuid({
                section_uuid: {
                    uuid: section_uuid
                },
                anemometer_uuid: {
                    uuid: autocompleteData[0].uuid
                },
            })
        } else if (isEndpoint && autocompleteData.length) {
            mapEndpointToSectionUuid({
                section_uuid: section_uuid,
                endpoint_uuid: autocompleteData.map(item => item.uuid??''),
            })
        }
    }

    const resultresponse = isAnemometer ? anemometerResult : endpointResult;

    if ((resultresponse.isSuccess && resultresponse.data.status_code === 200)) {
        props.onClose();
    }

    const onInputChangeHandler = (_event: React.SyntheticEvent<Element, Event>, value: OptionData[], _reason: string) => {
        setAutocompleteData(value);
    }

    return (
        <div>

            <BootstrapDialog
                className="modal-outer"
                onClose={props.onClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                    {props.title}
                </BootstrapDialogTitle>
                <Box component="form" onSubmit={handleSubmit}>
                    <DialogContent dividers>
                        <Autocomplete
                            multiple
                            onChange={onInputChangeHandler}
                            onInputChange={handleSearchChange}
                            id="tags-standard"
                            className="form-control-outer"
                            options={options}
                            getOptionLabel={(option) => option.name ?? ''}
                            isOptionEqualToValue={(option, value) => (option.uuid === value.uuid)}
                            renderInput={(params) => (
                                <TextField

                                    {...params}
                                    variant="standard"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {(isLoading || endPointLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    placeholder="start typing... "
                                />
                            )}
                        />
                    </DialogContent>
                    <DialogActions className="modalfooter">
                        <Button className="btn customer-form-btn" autoFocus type="submit">
                            Submit
                        </Button>
                    </DialogActions>
                </Box>
            </BootstrapDialog>
        </div>
    );
}

export default MapSectionDialogBox
