import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { CustomerApi, SiteApi, EndpointApi, AnemometerApi, SectionApi, GatewayApi } from "redux-services";
import SiteReducer from "redux-services/slices/SiteSlices";
import CustomerReducer from "redux-services/slices/CustomerSlices";
import AnemomenterReducer from "redux-services/slices/AnemometerSlices";
import EndPointReducer from "redux-services/slices/EndPointSlices";
import AlertBoxReducer from "redux-services/slices/AlertBoxSlice";
import GatewayReducer from "redux-services/slices/GatewaySlices";
import FormReducer from "redux-services/slices/FormSlices";
import MapReducer from "redux-services/slices/MapSlices";
import AuthReducer from "redux-services/slices/AuthSlice";
import { LoginApi } from "redux-services/apis/LoginApi";

export const store = configureStore({
  reducer: {
    alertbox: AlertBoxReducer,
    customer: CustomerReducer,
    site: SiteReducer,
    anemomenter: AnemomenterReducer,
    endpoint: EndPointReducer,
    gateway: GatewayReducer,
    form: FormReducer,
    map: MapReducer,
    auth: AuthReducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
    [CustomerApi.reducerPath]: CustomerApi.reducer,
    [SiteApi.reducerPath]: SiteApi.reducer,
    [AnemometerApi.reducerPath]: AnemometerApi.reducer,
    [EndpointApi.reducerPath]: EndpointApi.reducer,
    [SectionApi.reducerPath]: SectionApi.reducer,
    [GatewayApi.reducerPath]: GatewayApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(CustomerApi.middleware)
      .concat(SiteApi.middleware)
      .concat(EndpointApi.middleware)
      .concat(AnemometerApi.middleware)
      .concat(SectionApi.middleware)
      .concat(GatewayApi.middleware)
      .concat(LoginApi.middleware)
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
