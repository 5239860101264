import React, { useEffect, useState } from 'react';
import Leaflet, {type Map, type MapOptions, type Marker as LeafletMarker, type TileLayerOptions} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { useDispatch, useSelector } from "react-redux";
import { Marker } from "types";
import 'leaflet.markercluster';
import yellowMarker from "assets/svg/icon-pin1-yellow.svg"
import redMarker from "assets/svg/icon-pin1-red.svg"

import { getMarkers } from 'redux-services';


const mapOption: MapOptions = {
    minZoom: 2,
};

export const tileLayerUri: string = 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';

const tileLayerOption: TileLayerOptions = {
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    attribution: '&copy; NevadaNano',
    noWrap: true

};

const document: Document = window.document;

const yellowIcon = Leaflet.icon({ popupAnchor: [5, 0], iconUrl: yellowMarker, className: 'marker_popup' })
const redIcon = Leaflet.icon({ popupAnchor: [5, 0], iconUrl: redMarker, className: 'marker_popup' })

export interface CoreMapProps {
    title?: string;
}

export const CoreMap: React.FunctionComponent<CoreMapProps> = (_props: CoreMapProps) => {

 
    const [map, setMap] = useState<any>(null)
    
    const markerText = (title: string, lat: number, lng: number) => {
        return `<p class="modalusername">${title}</p>
                <p><span>Lat.</span> ${lat}</p>
                 <p> <span>Lon.</span> ${lng}</p>`
        
     }
    let markersData = useSelector(getMarkers); 

    useEffect(() => {
        
        if(map){
            
            map.eachLayer((layer: any) => {
                if(!layer._url){
                    map.removeLayer(layer);
                }
            })

            const layers: LeafletMarker[] = [];
            if(markersData.length){

                markersData.forEach((marker: Marker) => {
                    const newLayer = Leaflet.marker([marker.marker.lat, marker.marker.lng],{icon: marker.selected ? redIcon: yellowIcon}).bindPopup(markerText(marker.title??'<Title>',marker.marker.lat, marker.marker.lng))
                    layers.push(newLayer);
                })
    
                
                const ftrGrp = Leaflet.featureGroup(layers); 
    
                ftrGrp.addTo(map);
                map.fitBounds(ftrGrp.getBounds());
            }
            
        }
    },[markersData, map])
    
    useEffect(() => {
    
        let renderMap: Map = Leaflet.map('map', mapOption);
        Leaflet.tileLayer(tileLayerUri, tileLayerOption).addTo(renderMap);
        setMap(renderMap);
        
    }, [])

    return <div className="col-sm-6">
        <div className="chart-container" id="map-element">

            <div id="map" style={{ width: '95vw', height: '50vh' }} />
            
        </div>
    </div>
}