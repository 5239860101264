import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Footer: React.FunctionComponent = (props) => {
    return (
        <Box className="footer">
            <Typography textAlign="center">
                © 2022 NevadaNano | MPS™ Gas Sensor | ALL RIGHTS RESERVED | PRIVACY POLICY
            </Typography>

        </Box>

    )
}

export default Footer;