import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Endpoint } from 'types';

export interface CustomerDetailViewProps {
    endpoint: Endpoint | undefined;
}
const EndpointDetailView: React.FunctionComponent<CustomerDetailViewProps> = (
    props: CustomerDetailViewProps
) => {
    const endpoint = props.endpoint;

    const endpointData = [
        {
            label: 'Name',
            key: 'end_point_id'
        },
        {
            label: 'Owner',
            key: 'owner'
        }, 
        {
            label: 'EUI',
            key: 'eui'
        }, 
        {
            label: 'Latitude',
            key: 'lat'
        }, 
        {
            label: 'Longitude',
            key: 'lon'
        }, 
        {
            label: 'App Key',
            key: 'app_key'
        },
        {
            label: 'Created At',
            key: 'insert_datetime'
        }
    ]

    if (endpoint) {
        return (
            <Box className='detailsbody'>
                <Grid container>
                    <>
                        {endpointData.map((data, index) =>
                            <Grid item xs={6} md={4} key={index}>
                                <Box className='userdetailsinner'>
                                    <label>{data.label}</label>
                                    <Typography variant='subtitle1'>{endpoint[data.key as keyof Endpoint]}</Typography>
                                </Box>
                            </Grid>
                        )
                        }
                    </>
                </Grid>
            </Box>
        );
    } else {
        return <></>;
    }
};

export default EndpointDetailView;
