import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export interface FormState {
    value: any,
    files: any,
    valid: boolean,
    validators: any,
    errors: any,
}

const customParseFloat = (number: any) => {
    if(isNaN(parseFloat(number as string)) === false){
      let toFixedLength = 0;
      let str = String(number);
  
     
      [".", ","].forEach(seperator=>{
        let arr = str.split(seperator);
        if( arr.length === 2 ){
          toFixedLength = arr[1].length;
        }
      })
  
      return parseFloat(str).toFixed(toFixedLength);
    }
  
    return number;
  }

export const FormSlice = createSlice({
    name: 'form',
    initialState: {
        value: {},
        files: {},
        valid: false,
        validators: {},
        errors: {},

    } as FormState,
    reducers: {
        initForm: (state, action) => {

            // make all fields empty first
            state.value = {};
            state.valid = false;
            state.validators = {};
            state.errors = {};

            // init all values
            for (let keyField in action.payload.validators) {

                state.errors[keyField] = '';
                state.validators[keyField] = {
                    required: false,
                    validate: []
                };

                state.validators[keyField]['required'] = !!action.payload.validators[keyField].required
                state.validators[keyField]['validate'] = action.payload.validators[keyField].validators?.length ? [...action.payload.validators[keyField].validators] : [];
            }

            for (let keyField in action.payload.values) {

                state.value[keyField] = Object.keys(action.payload.values??{})?.length ? action.payload.values[keyField]: '';
                state.errors[keyField] = '';
                
            }
        },
       

        changeValue: (state, action) => {

            const { value, name } = action.payload.target;
            const errors = []

            if (state.validators[name].required) {
                if (!value) {
                    errors.push('Field is required!')
                }
            }
            errors.push(...state.validators[name]['validate'].map((validator: any) => (
                validator.validate(value, name)
            )))


            state.errors[name] = errors?.length ? errors.filter(v => v !== '')[0] : '';
            state.value[name] = value;
            let valid = true

            for (let FieldKey in state.validators) {
                if (state.validators[FieldKey].required) {
                    if (!state.value[FieldKey]) {
                        valid = false;
                    }
                }


                for (let validator of state.validators[FieldKey].validate) {

                    if (validator.validate(state.value[FieldKey], FieldKey)) {
                        valid = false;
                    }
                }
            }
            state.valid = valid;

        },

        setErrors: (state, action) => {
            state.valid = false;
            state.errors = { ...state.errors, ...action.payload }
        },

        validateForm: (state, action) => {
            let valid = true;

            let errors: any = {};

            for (let FieldKey in state.validators) {
                if (state.validators[FieldKey].required) {
                    if (!state.value[FieldKey]) {
                        valid = false
                        errors[FieldKey] = 'Field is required!'
                    }

                }


                for (let validator of state.validators[FieldKey].validate) {

                    let msg = validator.validate(state.value[FieldKey], FieldKey)

                    if (msg) {
                        valid = false
                        if (!errors[FieldKey]) {
                            errors[FieldKey] = msg
                        }
                    } else {
                        if (!errors[FieldKey]) {
                            errors[FieldKey] = ''
                        }
                    }
                }
            }

            state.valid = valid
            state.errors = errors

        }
    }


})



export const { initForm, changeValue, setErrors, validateForm } = FormSlice.actions
const FormReducer = FormSlice.reducer
export default FormReducer

export const formValues = (state: RootState) => state.form.value
export const formErrors = (state: RootState) => state.form.errors
export const isValid = (state: RootState) => state.form.valid
export const getValidators = (state: RootState) => state.form.validators
