import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Customer } from 'types';

export interface CustomerDetailViewProps {
  customer: Customer | undefined;
}
const CustomerDetailView: React.FunctionComponent<CustomerDetailViewProps> = (
  props: CustomerDetailViewProps
) => {
  const customer = props.customer;
  if (customer) {
    return (
        <Box className='detailsbody'>
          <Grid container>
            <Grid item xs={6} md={4}>
              <Box className='userdetailsinner'>
                <label>Name</label>
                <Typography variant='subtitle1'>{customer.name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box className='userdetailsinner'>
                <label>Email</label>
                <Typography variant='subtitle1'>{customer.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box className='userdetailsinner'>
                <label>Parent</label>
                {/* <Typography variant='subtitle1'>{customer.parent_uuid}</Typography> */}
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box className='userdetailsinner'>
                <label>Address</label>
                <Typography variant='subtitle1'>
                  {customer.address_line1} {customer.address_line2}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box className='userdetailsinner'>
                <label>State</label>
                <Typography variant='subtitle1'>{customer.state}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box className='userdetailsinner'>
                <label>Zip Code</label>
                <Typography variant='subtitle1'>{customer.zip}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} md={4}>
              <Box className='userdetailsinner'>
                <label>Country</label>
                <Typography variant='subtitle1'>{customer.country}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
    );
  } else {
    return <></>;
  }
};

export default CustomerDetailView;
