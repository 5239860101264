import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router-dom';
import { CustomerListBreadcrum, PageBreadCrum } from 'components/authenticated/common/Breadcrum';
import Layout from 'components/authenticated/common/Layout';
import TabPanel from 'components/authenticated/common/TabPanel';
import { setMarkers, useGetAnemometerBySectionUuidQuery, useGetAnemometerBySiteIdQuery, useGetAnemometerByUuidQuery, useGetCustomerByFieldQuery, useGetSiteByUuidQuery } from 'redux-services';
import { useDispatch } from 'react-redux';
import AnemometerDetailView from './AnemometerDetailView';
import { Marker } from 'types';
import ActionView from 'components/authenticated/common/ActionView';
import { AppDispatch } from 'app/store';
import { CoreMap } from 'components/authenticated/common/map/CoreMap';


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AnemometerTabView: React.FunctionComponent = () => {
    const [value, setValue] = React.useState(0);

    const { uuid, site_uuid, anemometer_uuid, section_uuid } = useParams();


    const dispatch = useDispatch<AppDispatch>();

    const { isLoading, data: customerData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    });

    const { data: siteData } = useGetSiteByUuidQuery(site_uuid);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { data: anemometers } = useGetAnemometerBySiteIdQuery({
        site_uuid,
        searchText: ''
      },{
        skip: section_uuid? true: false
      });
  
      const { isSuccess: succ, data: sectionAnemometers } = useGetAnemometerBySectionUuidQuery({
        section_uuid,
    },{
        skip: !section_uuid
    });
  
    const pageData = section_uuid? sectionAnemometers: anemometers;
  
    const { data: anemometerData, isSuccess } = useGetAnemometerByUuidQuery(anemometer_uuid);
   
    useEffect(() => {
        if(pageData && (isSuccess || succ)){
          const markers: Marker[] = pageData.result.map((anemometer: any) => {
            const anemometerItem = section_uuid? anemometer.anemometer_uuid: anemometer;
            return {
                selected: anemometerData?.result[0].uuid === anemometerItem.uuid, 
                title: anemometerItem.anemometer_id ,
                marker: {
                    lat: anemometerItem.lat ?? 0,
                    lng: anemometerItem.lon ?? 0
                }
            }
          }) ;
          dispatch(setMarkers(markers));
        }    
    }, [anemometers, sectionAnemometers, anemometerData]);
  
    
    const getUrl = (type: string) => {
        return `/customers/detail/${uuid}/site/detail/${site_uuid}/anemometer/${type}/${anemometer_uuid}`
    }

    const customerDetailBreadcrum: PageBreadCrum[] = [
        CustomerListBreadcrum,
        {
            description: "Customer Detail",
            displayName: customerData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Site Detail",
            displayName: siteData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}`
        },
        {
            description: "anemometer Detail",
            displayName: anemometerData?.result[0].anemometer_id ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}/anemometer/detail/${anemometer_uuid}`
        }

    ]
    return (
        <>
            <Layout isLoading={isLoading} breadcrums={customerDetailBreadcrum}>
                <Box className='mainsection'>
                    <Box className='tabouter'>
                        <Box className='tabinnerbox' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label='basic tabs example'
                                className='tabitemouter'
                            >
                                <Tab label='Anemometer Details' {...a11yProps(0)} />
                            </Tabs>
                            <Box className="searchrighttab addcustomerrightpart">
                                {value == 0 && (
                                    <Box>
                                        <ActionView actionClick={getUrl('edit')} />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box className='borderdiv'>

                            <TabPanel value={value} index={0}>
                                <AnemometerDetailView anemometer={anemometerData?.result[0]} />
                            </TabPanel>

                        </Box>
                    </Box>
                    <Box className="sitemap">
                        <CoreMap />
                    </Box>
                </Box>
            </Layout>
        </>
    );
};

export default AnemometerTabView;
