
export class LengthValidator {

    length: number;
    minOrMax: 'min' | 'max';
    message: string

    constructor(length: number, minOrMax: 'min' | 'max', message = '') {
        this.length = length
        this.minOrMax = minOrMax
        this.message = message
    }

    /** 
     * every validator should have this validate method
     * automatically input will inject from store the exact value form this key
     * return error message
     * */
    validate(value: string, fld_name = '') {
        

        if (this.minOrMax === 'min') {
            if (value && value.length < this.length) {
                return this.message || 'Minimum ' + this.length + ' characters allowed!'
            }
        }

        if (this.minOrMax === 'max') {
            if (value && value.length > this.length) {
                return this.message || `Maximum ${this.length} characters allowed!`
            }
        }

        return ''
    }
}

export class EmailValidator {

    validate(value: string, fld_name = '') {
        if (value && !value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            return 'Email is not valid'
        }

        return ''
    }
}
export class FloatValidator {
    validate(value: string, fld_name = '') {
        if (value && !String(value).match(/^[+-]?([0-9]*[.])?[0-9]+$/)) {
            return "Not a valid float value";
        }
        return ''
    }
}
export class PositiveValueValidator {

    validate(value: string, fld_name = '') {
        if (value && !String(value).match(/^[0-9]\d*$/)) {
            return "Please enter valid positive number";
        }
        return ''
    }
}