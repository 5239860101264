import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, Response, Gateway } from "types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface GatewayMeta extends PageMeta {
  site_uuid: string | undefined
}

export const GatewayApi = createApi({
  reducerPath: "gatewayApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["GatewayTag"],
  endpoints: (builder) => ({
    getGateway: builder.query<Response<Gateway[]>, GatewayMeta>({
      query: (pageData: GatewayMeta) => ({
        url: `/gateway`,
        method: "GET",
        params: {
          site_uuid: pageData.site_uuid,
          limit: pageData.per_page,
          page: pageData.page,
          order: "desc",
          search: pageData.searchText,
        },
      }),
      providesTags: ["GatewayTag"],
    }),
    addGateway: builder.mutation<Response<Gateway[]>, Gateway>(
      {
        query: (gateway: Gateway) => ({
          url: `/gateway`,
          method: "POST",
          body: gateway
        }),
        invalidatesTags: ["GatewayTag"],
      }
    ),
  }),
});

export const {
    useGetGatewayQuery,
    useAddGatewayMutation
} = GatewayApi;