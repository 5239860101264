import React, { ChangeEventHandler } from 'react';
import { OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';


export interface MuiSearchBoxProps {
    searchHandler: (searchText: string) => void;
    placeholder?: string;
}



const MuiSearchBox: React.FunctionComponent<MuiSearchBoxProps> = (props: MuiSearchBoxProps) => {

    const debouncedSearch = debounce(async (searchText: string) => {
        props.searchHandler(searchText);
    }, 500);



    async function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
        debouncedSearch(e.target.value);
    }
    return (
        <>
            <OutlinedInput id="search"
                className="input-search"
                placeholder={props.placeholder??"Search by name"}
                // value=""
                endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                onChange={handleSearchChange}
            />
        </>
    )
}

export default MuiSearchBox;
