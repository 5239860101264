import { Typography, Box, Grid, Button, FormHelperText, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { CustomerListBreadcrum, PageBreadCrum } from "components/authenticated/common/Breadcrum";
import Layout from "components/authenticated/common/Layout";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { changeValue, formValues, initForm, formErrors, isValid, validateForm } from "redux-services/slices/FormSlices";
import { Anemometer, FormElement } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { FloatValidator, LengthValidator, useAddAnemometerMutation, useEditAnemometerMutation, useGetAnemometerByUuidQuery, useGetApplicationQuery, useGetCustomerByFieldQuery, useGetSiteByUuidQuery, useGetDeviceProfileQuery } from "redux-services";
import { ApplicationViewState } from "components/authenticated/endpoint/endpoint-editor/EndpointEditor";
import CancelView from "components/authenticated/common/CancelView";


const AnemometerEditor: React.FunctionComponent = () => {

    const dispatch = useDispatch<AppDispatch>();

    const changeFormValue = (event: React.ChangeEvent<HTMLInputElement>) => dispatch(changeValue(event));
    const changeSelectValue = (event: SelectChangeEvent<string>) => dispatch(changeValue(event));

    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState('Add');

    const { uuid, site_uuid, anemometer_uuid } = useParams();

    const { data: anemometerData } = useGetAnemometerByUuidQuery(anemometer_uuid, { skip: !anemometer_uuid });

    const formElementData: FormElement[] = [
        {
            label: 'Anemometer Id',
            attributes: {
                name: 'anemometer_id',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]

        },
        {
            label: 'Owner',
            attributes: {
                name: 'owner',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },

        {
            label: 'Latitude',
            attributes: {
                name: 'lat',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new FloatValidator()]
        },

        {
            label: 'Longitude',
            attributes: {
                name: 'lon',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new FloatValidator()]
        },
        {
            label: 'App Key',
            attributes: {
                name: 'app_key',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(40, 'max')]
        },
        {
            label: 'EUI',
            nonEditable: true,
            attributes: {
                name: 'eui',
                className: 'form-control',
                onChange: changeFormValue,
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(40, 'max')]
        },
        {
            label: 'Application Name',
            nonEditable: true,
            attributes: {
                name: 'application_name',
                className: 'form-control app-select',
                onSelectChange: changeSelectValue,
                type: 'select',
                required: true
            }
        },
        {
            label: 'Device Profile Name',
            nonEditable: true,
            attributes: {
                name: 'device_profile_name',
                className: 'form-control app-select',
                onSelectChange: changeSelectValue,
                type: 'select',
                required: true
            }
        }

    ];

    // first define the form structure to init to store
    const form = {
        validators: {
            ...formElementData.reduce((a, v) => ({ ...a, [v.attributes.name]: (anemometer_uuid && v.nonEditable) ? {} : { 'required': v.attributes.required, validators: v.validators } }), {})
        },
        values: {
            ...formElementData.reduce(
                (a, v) => ({ ...a, [v.attributes.name]: anemometer_uuid && anemometerData ? anemometerData?.result[0][v.attributes.name as keyof Anemometer] : v.attributes.type == 'number' ? 0 : '' }), {})
        }
    }


    useEffect(() => {
        dispatch(initForm(form))
        if (anemometer_uuid) {
            setPageTitle('Edit')
            dispatch(validateForm(AnemometerEditorFormValue))
        }
    }, [anemometerData])

    const initialState: ApplicationViewState = {
        page: 1,
        perPage: 28,
        searchText: ""
    }

    const [applicationState, setApplicationState] = useState<ApplicationViewState | undefined>(initialState);

    const { data: applicationData } = useGetApplicationQuery({
        per_page: applicationState?.perPage ?? 28,
        page: applicationState?.page ?? 1,
        searchText: applicationState?.searchText ?? ""
    });

    const AnemometerEditorFormValue = useSelector(formValues)

    const [addAnemometer, result] = useAddAnemometerMutation();
    const [editAnemometer, editResult] = useEditAnemometerMutation();


    const errors = useSelector(formErrors)

    const isFormValid = useSelector(isValid)

    const { data: customerData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    });

    const { data: deviceProfileData } = useGetDeviceProfileQuery({
        per_page: applicationState?.perPage ?? 28,
        page: applicationState?.page ?? 1,
        searchText: applicationState?.searchText ?? ""
    })

    const { data: siteData } = useGetSiteByUuidQuery(site_uuid);


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(validateForm(AnemometerEditorFormValue))
        if (isFormValid) {
            const formData = {
                site_uuid,
                org_name: customerData?.result[0].name,
                ...AnemometerEditorFormValue
            }
            anemometer_uuid ? editAnemometer({ ...formData, uuid: anemometer_uuid }) : addAnemometer(formData)
        }
    }

    if ((result.isSuccess && result.data && result.data.status_code === 200) || (editResult.isSuccess && editResult.data && editResult.data.status_code === 200)) {
        navigate(`/customers/detail/${uuid}/site/detail/${site_uuid}/anemometer/detail/${anemometer_uuid ?? result.data?.result[0].uuid}`);
    }

    const addCustomerBreadcrumbs: PageBreadCrum[] = [
        CustomerListBreadcrum,
        {
            description: "Customer Detail",
            displayName: customerData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}`
        },
        {
            description: "Site Detail",
            displayName: siteData?.result[0].name ?? '',
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}`
        },
        {
            description: anemometer_uuid ? "Edit" : "Add" + " Anemometer",
            displayName: anemometer_uuid ? "Edit" : "Add" + " Anemometer",
            link: `/customers/detail/${uuid}/site/detail/${site_uuid}/anemometer/${anemometer_uuid ? 'edit/' + anemometer_uuid : 'add'}`
        }

    ]

    return (
        <Layout isLoading={result.isLoading} breadcrums={addCustomerBreadcrumbs}>
            <Box className="customer-list">
                <Box className="searchouterright">
                    <Box className="pageheading">
                        <Typography variant="h2">{pageTitle} Anemometer</Typography>
                    </Box>
                </Box>
                <Box component="form" onSubmit={handleSubmit} className="customerform">

                    <Grid container spacing={3}>
                        <>
                            {formElementData.map((item, index) =>
                                ((anemometer_uuid && !item.nonEditable) || !anemometer_uuid) &&
                                <Grid key={index} item xs={item.xs ?? 12} md={6}>
                                    <Box className="formgroup">
                                        {item.attributes.type === 'select' ? (
                                            <>
                                                {item.attributes.name === 'application_name' && (
                                                    <>
                                                        <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                                        <Select
                                                            displayEmpty
                                                            labelId={item.label}
                                                            id="demo-simple-select"
                                                            label="Select Application"
                                                            value={Object.keys(AnemometerEditorFormValue).length ? AnemometerEditorFormValue[item.attributes.name] : ''}
                                                            name={item.attributes.name}
                                                            className={item.attributes.className}
                                                            onChange={item.attributes.onSelectChange}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Select Application</em>
                                                            </MenuItem>
                                                            {applicationData && applicationData.result.map((aitem, index) =>
                                                                <MenuItem key={index} value={aitem.name}>{aitem.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </>
                                                )}

                                                {item.attributes.name === 'device_profile_name' && (
                                                    <>
                                                        <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                                        <Select
                                                            displayEmpty
                                                            labelId={item.label}
                                                            id="demo-simple-select-device-profile-name"
                                                            label="Select Device Profile"
                                                            value={Object.keys(AnemometerEditorFormValue).length ? AnemometerEditorFormValue[item.attributes.name] : ''}
                                                            name={item.attributes.name}
                                                            className={item.attributes.className}
                                                            onChange={item.attributes.onSelectChange}
                                                        >
                                                            <MenuItem disabled value="">
                                                                <em>Select Device Profile</em>
                                                            </MenuItem>
                                                            {deviceProfileData && deviceProfileData.result.map((ditem, index) =>
                                                                <MenuItem key={index} value={ditem.name}>{ditem.name}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </>
                                                )}
                                            </>
                                        ) : (

                                            <>
                                                <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                                <TextField
                                                    type={item.attributes.type}
                                                    id={item.attributes.name}
                                                    variant="outlined"
                                                    value={Object.keys(AnemometerEditorFormValue).length ? AnemometerEditorFormValue[item.attributes.name] : ''}
                                                    className={item.attributes.className}
                                                    name={item.attributes.name}
                                                    onChange={item.attributes.onChange}
                                                />
                                            </>
                                        )}
                                        <FormHelperText error={errors[item.attributes.name] ? true : false}>{errors[item.attributes.name]}</FormHelperText>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box className="btn-group">
                                    <CancelView actionUrl={`/customers/detail/${uuid}/site/detail/${site_uuid}`} />
                                    <Button type="submit" className="btn customer-form-btn "> Submit</Button>
                                </Box>
                            </Grid>
                        </>
                    </Grid>
                </Box>
            </Box>
        </Layout>
    );
};

export default AnemometerEditor;
