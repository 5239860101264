import { createSlice } from "@reduxjs/toolkit";
import { GatewayApi } from "redux-services/apis";
import { Gateway } from "types";


export interface GatewayState {
    gatewayList: Gateway[];
}

const initialState: GatewayState = {
    gatewayList: [],
};

const GatewaySlices = createSlice({
  name: "gatewaySlices",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getGateway: (state, action) => {
      
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
        GatewayApi.endpoints.getGateway.matchFulfilled,
      (state, { payload }) => {
        state.gatewayList = state.gatewayList.concat(payload.result);
      }
    );
  },
});

const { reducer, actions } = GatewaySlices;

export const { getGateway } = actions;

export default reducer;