import { useNavigate } from "react-router-dom";

// Assest 
import logoImage from "assets/img/logo_nevadanano.png";
// MUI Import
import {
    Card,
    CardActions,
    Typography,
    Button,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Link,
    TextField
} from "@mui/material";
import { LoginRequest, useDoLoginMutation } from "redux-services/apis/LoginApi";
import { useState } from "react";
import AlertBox from "components/authenticated/common/AlertBox";



const Login: React.FunctionComponent = (_props) => {
    const navigate = useNavigate();
    const [doLogin, result] = useDoLoginMutation();

    const [formData, setFormData] = useState<LoginRequest>({
        email: "",
        password: "",
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const loginHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        doLogin(formData)        
    };

    if((result.isSuccess && result.data.status_code === 200) || localStorage.userAccessToken) {
        navigate('/customers')
    }

    return (
        <>
            <div className="login-container">
                <Card className="login-wrapper">
                    <form className="login-form" onSubmit={loginHandler}>
                        <CardContent>
                            <div className="login-image">
                                <img src={logoImage} alt="Logo" />
                            </div>
                            <Typography gutterBottom variant="h5" component="h2">
                                Hello! Let's Get Started
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Sign in to continue.
                            </Typography>
                            <AlertBox />
                            <FormControl>
                                <TextField label="Email" fullWidth={true} id="email" name="email" onChange={onChange} />
                            </FormControl>
                            <FormControl>
                                <TextField label="Password" fullWidth={true}  type="password"  name="password" onChange={onChange} />
                            </FormControl>
                            {/* <div className="login-Forget">
                                <FormControlLabel control={<Checkbox />} label="Keep me signed in" />
                                <Link href="https://" >Forget Password?</Link>
                            </div> */}
                        </CardContent>
                        <CardActions className="login-btn">
                            <Button fullWidth variant="contained" size="large" color="primary" type="submit" className="btn customer-form-btn "> Sign in</Button>
                        </CardActions>
                    </form>
                </Card>

            </div>
        </>
    );
}

export default Login;   