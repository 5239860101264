import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { SiteApi } from "redux-services/apis";
import { Site } from "types";


export interface SiteState {
  siteList: Site[];
}

const initialState: SiteState = {
  siteList: [],
};

export const CustomerSiteSlice = createSlice({
  name: "CustomerSiteslice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getSitesByCustomerUuid: (state, action) => {
      
    },

    createCustomerSite: (state, action) => {},
  },
  extraReducers(builder) {
    builder.addMatcher(
      SiteApi.endpoints.getSitesByCustomerUuid.matchFulfilled,
      (state, { payload }) => {
        state.siteList = state.siteList.concat(payload.result);
      }
    );
  },
});

const { reducer, actions } = CustomerSiteSlice;

export const { getSitesByCustomerUuid } = actions;

export const selectSite = (columnName: keyof Site, value: any) => {
  return (state: RootState) => state.site.siteList.find((site: Site) => site[columnName] === value)
}

export default reducer;
