import { Box, Button, Typography } from "@mui/material";
import React from "react";
import NotFound from "assets/img/notfound.png";
import { Link } from "react-router-dom";


export interface NoRecordProps {
    title?: string;
}

const NoRecord: React.FunctionComponent<NoRecordProps> = (props: NoRecordProps) => {
    return (
        <Box className="noproduct">
            <div className="notfound-outer">
                {/* <img src={NotFound} alt="" /> */}
                <Typography>{`No ${props.title} found`}</Typography>
                {/*     <Link className="btn" to="/">Back to Home</Link> */}
            </div>
        </Box>
    )
}

export default NoRecord;