import { Typography, Box, Grid, Button, FormHelperText, Autocomplete, debounce, CircularProgress } from "@mui/material";
import { PageBreadCrum } from "components/authenticated/common/Breadcrum";
import Layout from "components/authenticated/common/Layout";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "app/store";
import { changeValue, formValues, initForm, formErrors, isValid, validateForm } from "redux-services/slices/FormSlices";
import { FormElement, Customer } from "types";
import { EmailValidator, LengthValidator, useAddCustomerMutation, useEditCustomerMutation, useGetCustomerByFieldQuery, useGetCustomersQuery } from "redux-services";
import { useNavigate, useParams } from "react-router-dom";
import { Email } from "@mui/icons-material";
import CancelView from "components/authenticated/common/CancelView";


export interface OptionData {
    name?: string | undefined;
    uuid?: string | undefined;
}

const CustomerEditor: React.FunctionComponent = () => {

    const { uuid } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const [ pageTitle, setPageTitle ] = useState('Add');
    const [searchText, setSearchText] = React.useState('');

    const changeFormValue = (event: React.ChangeEvent<HTMLInputElement>) => dispatch(changeValue(event));

    const formElementData: FormElement[] = [
        {
            label: 'email',
            attributes: {
                name: 'email',
                className: '',
                onChange: changeFormValue,
                // value: '',
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(100, 'max'), new EmailValidator()]
        },
        {
            label: 'name',
            attributes: {
                name: 'name',
                className: '',
                onChange: changeFormValue,
                value: '',
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'address Line',
            attributes: {
                name: 'address_line1',
                className: '',
                onChange: changeFormValue,
                // value: '',
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'address Line 2',
            attributes: {
                name: 'address_line2',
                className: '',
                onChange: changeFormValue,
                value: '',
                type: 'text',
                required: false
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'state',
            attributes: {
                name: 'state',
                className: '',
                onChange: changeFormValue,
                // value: '',
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'city',
            attributes: {
                name: 'city',
                className: '',
                onChange: changeFormValue,
                // value: '',
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        },
        {
            label: 'zip',
            attributes: {
                name: 'zip',
                className: '',
                onChange: changeFormValue,
                // value: '',
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(9, 'max')]
        },
        {
            label: 'country',
            attributes: {
                name: 'country',
                className: '',
                onChange: changeFormValue,
                // value: '',
                type: 'text',
                required: true
            },
            validators: [new LengthValidator(25, 'max')]
        }
    ];

    let {isLoading, data: customerData } = useGetCustomersQuery({ searchText });

    const {data: customerEditData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: uuid
    },{
        skip: !uuid
    });

    

    const options: OptionData[] = customerData?.result.map((item, _index) => {
        return {
            uuid: item.uuid,
        }
    }) ?? [{}];

    let parentCustomer = options.find((item) => item.uuid === customerEditData?.result[0]['parent_uuid']?.uuid);

    const { data: customerParentData } = useGetCustomerByFieldQuery({
        field: 'customer_uuid',
        value: customerEditData?.result[0]['parent_uuid']?.uuid
    },{
        skip: (!customerEditData && parentCustomer) as boolean 
    });
    

    parentCustomer = parentCustomer ?? customerParentData?.result.map((item, _index) => {
        return {
            uuid: item.uuid,
            name: item.name
        }
    })[0];
    

    // first define the form structure to init to store
    const form = {
        validators: {
            parent_uuid : {
                required: false
            },
            ...formElementData.reduce((a, v) => ({ ...a, [v.attributes.name]: { 'required': v.attributes.required, validators: v.validators } }), {})
        },
        values: {
            parent_uuid: uuid && customerEditData && parentCustomer? parentCustomer:{},
            ...formElementData.reduce(
                (a, v) => ({ ...a, [v.attributes.name]: uuid && customerEditData ? customerEditData?.result[0][v.attributes.name as keyof Customer]: v.attributes.type == 'number'? 0 :'' }), {})
        }
    }

    

    const addCustomerBreadcrumbs: PageBreadCrum[] = [
        {
            description: "Customer list",
            displayName: "Customers",
            link: `/customers`,
        },
        {
            description: uuid ? 'Edit' : 'Add' + " Customer",
            displayName: uuid ? 'Edit' : 'Add' + " Customer",
            link: "/customers/" + (uuid ? 'edit/' + uuid : 'Add'),
        },
    ];

    const navigate = useNavigate();

    const CustomerEditorFormValue = useSelector(formValues)

    const [addCustomer, result] = useAddCustomerMutation();
    const [editCustomer, editResult] = useEditCustomerMutation();

    const errors = useSelector(formErrors)

    const isFormValid = useSelector(isValid)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        dispatch(validateForm(CustomerEditorFormValue))
        
        if (isFormValid) {
            
            let customerFormData = {
                ...CustomerEditorFormValue,
                parent_uuid: {
                    uuid: CustomerEditorFormValue.parent_uuid?.uuid ?? null,
                },
                photo: ''
            }
            uuid ? editCustomer({...customerFormData, uuid}) : addCustomer(customerFormData)
        }
    }

    
    const debouncedSearch = debounce(async (search: string) => {
        setSearchText(search);
    }, 500);

    async function handleSearchChange(_event: React.SyntheticEvent, value: string, _reason: string) {
        debouncedSearch(value);
    }

    if (result.isSuccess && result.data && result.data.status_code === 200 || editResult.isSuccess && editResult.data && editResult.data.status_code === 200) {
        navigate(`/customers/detail/${uuid ?? result.data?.result[0].uuid}`);
    }

    const onOptionSelectionChangeHandler = (_event: React.SyntheticEvent<Element, Event>, value: OptionData | null, _reason: string) => {
        dispatch(changeValue({target:{
            name:"parent_uuid",
            value
        }}))
    }

    useEffect(() => {
        dispatch(initForm(form))
        if(uuid){
            setPageTitle('Edit')
            dispatch(validateForm(CustomerEditorFormValue))
        }
    }, [customerEditData])

    return (
        <Layout isLoading={result.isLoading} breadcrums={addCustomerBreadcrumbs}>
            <Box className="customer-list">
                <Box className="searchouterright">
                    <Box className="pageheading">
                        <Typography variant="h2">{pageTitle} Customer</Typography>
                    </Box>
                </Box>
                <Box component="form" onSubmit={handleSubmit} className="customerform">

                    <Grid container spacing={3}>
                        <>
                            <Grid item xs={12}>
                                <Box className="formgroup mb-0">
                                    <label>Parent</label>
                                    <Autocomplete
                                        onChange={onOptionSelectionChangeHandler}
                                        onInputChange={handleSearchChange}
                                        id="tags-standard"
                                        // value="zxc"
                                        className="form-control-outer"
                                        value={Object.keys(CustomerEditorFormValue).length ? CustomerEditorFormValue['parent_uuid'] : ''}
                                        options={options}
                                        getOptionLabel={(option) => customerData?.result.find((item)=> item.uuid === option.uuid)?.name?? ''}
                                        isOptionEqualToValue={(option, value) => (option.uuid === value.uuid) }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                name="parent_uuid"
                                                // value={customerEditData?.result[0]?.parent_uuid }
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {(isLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                placeholder="Search Parent... "
                                            />
                                        )}
                                    />
                                    <FormHelperText error={errors['parent_uuid'] ? true : false}>{errors['parent_uuid']}</FormHelperText>
                                </Box>
                            </Grid>
                            {formElementData.map((item, index) => <Grid key={index} item xs={6}>
                                <Box className="formgroup">
                                    <label style={{ textTransform: 'capitalize' }}>{item.label}</label>
                                    <TextField
                                        id={item.attributes.name}
                                        variant="outlined"
                                        className="form-control {item.className}"
                                        name={item.attributes.name}
                                        onChange={item.attributes.onChange}
                                        value={Object.keys(CustomerEditorFormValue).length ? CustomerEditorFormValue[item.attributes.name] : ''}
                                    />
                                    <FormHelperText error={errors[item.attributes.name] ? true : false}>{errors[item.attributes.name]}</FormHelperText>
                                </Box>
                            </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box className="btn-group">
                                    <CancelView actionUrl="/customers" />
                                    <Button type="submit" className="btn customer-form-btn "> Submit</Button>
                                </Box>
                            </Grid>
                        </>
                    </Grid>
                </Box>
            </Box>
        </Layout>
    );
};

export default CustomerEditor;
