import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Customer } from "types";
import { CustomerApi } from "../apis/CustomerApi";

export interface CustomerState {
  customerList: Customer[];
  page: number;
  perPage: number;
  total: number;
}

const initialState: CustomerState = {
  customerList: [],
  page: 1,
  perPage: 28,
  total: 0,
};

export const CustomerSlice = createSlice({
  name: "customerslice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      CustomerApi.endpoints.getCustomers.matchFulfilled,
      (state, { payload }) => {
        state.customerList = state.customerList.concat(payload.result);
      }
    );
  },
});

const { reducer, actions } = CustomerSlice;

export const selectCustomer = (columnName: keyof Customer, value: any) => {
  return (state: RootState) => state.customer.customerList.find((customer: Customer) => customer[columnName] === value)
}

export default reducer;
