import { createSlice } from "@reduxjs/toolkit";
import { AnemometerApi } from "redux-services/apis/AnemometerApi";
import { Anemometer} from "types";


export interface AnemometerState {
    anemometerList: Anemometer[];
    selectedAnemometer: string[];
}

const initialState: AnemometerState = {
    anemometerList: [],
    selectedAnemometer: [],
};

const AnemometerSlices = createSlice({
  name: "anemometerSlices",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedAnemometer: (state, action) => {
      state.selectedAnemometer = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
        AnemometerApi.endpoints.getAnemometerBySiteId.matchFulfilled,
      (state, { payload }) => {
        state.anemometerList = state.anemometerList.concat(payload.result);
      }
    );
  },
});

const { reducer, actions } = AnemometerSlices;

export const { setSelectedAnemometer } = actions;

export default reducer;