import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { ListViewState, Endpoint, Marker } from "types";
import { useGetEndpointBySiteIdQuery } from "redux-services/apis/EndpointApi";
import { setMarkers, setSelectedEndpoint, useGetEndpointBySectionUuidQuery } from "redux-services";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { CoreMap } from "../common/map/CoreMap";
import NoRecord from "../common/NoRecord";



export interface EndPointListViewProps {
    searchText?: string;
    isSection?: boolean;
}

export interface EndpointListViewState extends ListViewState {

}

const EndPointListView: React.FunctionComponent<EndPointListViewProps>
    = (props: EndPointListViewProps) => {

        const initialState: EndpointListViewState = {
            page: 1,
            perPage: 20,
            searchText: ""
        }

        const [endpointListState, setEndpointListState] = useState<EndpointListViewState>(initialState);

        const dispatch = useDispatch<AppDispatch>();

        const { uuid, site_uuid, section_uuid } = useParams()

        const { isSuccess, data } = useGetEndpointBySiteIdQuery({
            site_uuid,
            page: endpointListState.page,
            per_page: endpointListState.perPage,
            searchText: props.searchText
        }, {
            skip: section_uuid ? true : false
        });

        const { isSuccess: succ, data: sectionEndponts } = useGetEndpointBySectionUuidQuery({
            section_uuid,
            page: endpointListState.page,
            per_page: endpointListState.perPage,
            searchText: props.searchText
        }, {
            skip: !section_uuid
        });

        const pageData = section_uuid ? sectionEndponts : data;

        const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
            setEndpointListState({
                page: value,
                perPage: initialState.perPage,
            });
        }

        useEffect(() => {
            if (pageData && (isSuccess || succ)) {

                const markers: Marker[] = pageData ? pageData.result.map((endpoint: any) => {
                    const endpointItem = section_uuid ? endpoint.endpoint_uuid : endpoint;
                    return {
                        title: endpointItem.end_point_id,
                        marker: {
                            lat: endpointItem.lat ?? 0,
                            lng: endpointItem.lon ?? 0
                        }
                    }
                }) : [];
                dispatch(setMarkers(markers));
            }
        }, [sectionEndponts, data]);

        const checkedData = useSelector((state: RootState) => state.endpoint.selectedEndpoint)

        const handleCheckChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            if (checked) {
                dispatch(setSelectedEndpoint([...checkedData, e.target.name]));
            } else {
                dispatch(setSelectedEndpoint(checkedData.filter((selected: string) => selected !== e.target.name)))
            }

        }

        const handleParent = () => {
            if (pageData?.result?.length === checkedData.length) {
                dispatch(setSelectedEndpoint([]));
            } else {

                let listItems: string[] = [];
                pageData?.result.forEach((anemometer) => {
                    listItems.push(anemometer.uuid ?? '');
                })
                dispatch(setSelectedEndpoint(listItems));
            }


        }
        const allChecked = pageData?.result?.length === checkedData.length;

        return (
            <>
                {pageData?.result?.length ? (
                    <TableContainer component={Paper} className="sitetable">
                        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell rowSpan={2}>
                                        { section_uuid 
                                            && <Checkbox
                                                    checked={allChecked}
                                                    indeterminate={checkedData.length && !allChecked ? true : false}
                                                    onChange={handleParent}
                                                />
                                        }
                                        Endpoints Name
                                    </TableCell>
                                    <TableCell rowSpan={2}>Last Data Date</TableCell>
                                    <TableCell rowSpan={2}>Lat, Lon</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageData.result.map((endpoint: any) => (
                                    <TableRow key={endpoint.uuid}>
                                            
                                        <TableCell>
                                            {
                                                section_uuid && <Checkbox checked={checkedData.includes(endpoint.uuid)} name={endpoint.uuid} onChange={handleCheckChange} />
                                            }
                                            <Link
                                                className="site-link"
                                                to={`/customers/detail/${uuid}/site/detail/${site_uuid}/endpoint/detail/${section_uuid ? endpoint.endpoint_uuid.uuid : endpoint.uuid}`}
                                            >
                                                {section_uuid ? endpoint.endpoint_uuid.end_point_id : endpoint.end_point_id}
                                            </Link>{" "}
                                        </TableCell>
                                        <TableCell>{section_uuid ? endpoint.endpoint_uuid.insert_datetime : endpoint.insert_datetime}</TableCell>
                                        <TableCell>{section_uuid ? endpoint.endpoint_uuid.lat : endpoint.lat}, {section_uuid ? endpoint.endpoint_uuid.lon : endpoint.lon}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <Box className="paginationouter">
                                            <Stack spacing={2}>
                                                {/* <Pagination count={10} color='primary' /> */}
                                                <Pagination
                                                    page={pageData?.page ?? 1}
                                                    count={pageData?.total_pages ?? 1}
                                                    color="primary"
                                                    onChange={handlePageChange}
                                                />
                                            </Stack>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                ) : <NoRecord title="Endpoints" />}
                {pageData?.result?.length &&
                    <Box className="sitemap sitemap-bottom">
                        <CoreMap />
                    </Box>
                }
            </>
        )
    }


export default EndPointListView;
