import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Customer } from "types";

export interface CustomerViewProps {
    customer: Customer,
    key: string
} 
const CustomerView: React.FunctionComponent<CustomerViewProps> = (props: CustomerViewProps) => {

    const customer = props.customer;

    return (<>
        <Link to={"/customers/detail/" + customer.uuid} className="customer-link">
            <Card  className="customer-card">
                <CardContent className="customer-item">
                    <Typography variant="h2" className="avatertext">{customer.name[0]}</Typography>
                    {/* <Avatar alt="Remy Sharp" src="https://mui.com/static/images/avatar/1.jpg" /> */}
                    <h5>{customer.name}</h5>
                    <small>{customer.email}</small>
                </CardContent>
            </Card>
        </Link>
    </>);
}

export default CustomerView;