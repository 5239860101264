import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import { createTheme, ThemeProvider } from '@mui/material';
import { removeAlert, setAlert, setLoader } from 'redux-services';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { setAuthLogout } from 'redux-services/slices/AuthSlice';
import { Response } from 'types';


const Interceptor: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const showAlert = (error = false, message = "") => {
    dispatch(setAlert({
      type: error ? 'error' : 'success',
      message
    }));
    setTimeout(() => {
      console.log('hello');
       dispatch(removeAlert(0));
    }, 5000);
  }
  
  axios.interceptors.request.use(
    (request: AxiosRequestConfig): AxiosRequestConfig => {
      dispatch(setLoader());
      return request;
    },
    error => error
  )

  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      if(response.data.status_code === 401){
        localStorage.clear();
        dispatch(setAuthLogout())
        window.location.reload();
      }

      if (response.config.method != 'get') {
        showAlert(response.data.status_code !== 200, response.data.message);
      }
      dispatch(setLoader());
      return response;
    },
    (error: AxiosError<Response>): AxiosError => {
      dispatch(setLoader());
      showAlert(true, error.response?.data.message ?? error.message);
      if(error.response?.status === 401){
        localStorage.clear();
        dispatch(setAuthLogout())
        window.location.reload();
      }
      return error;
    }

  );
  return (
    <></>
  )
}
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={darkTheme}>
          <Interceptor />
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
