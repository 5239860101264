import React, { useEffect, useState } from "react";
import { Box, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { ListViewState, Marker, Section, Site } from "types";
import GoogleMap from "components/authenticated/customer/GoogleMap";
import { setMarkers, useGetSectionBySiteIdQuery } from "redux-services";
import { marker } from "leaflet";
import { useDispatch } from "react-redux";
import { CoreMap } from "../common/map/CoreMap";
import NoRecord from "../common/NoRecord";


export interface SectionListViewProps {
    searchText?: string;
}
export interface SectionListViewState extends ListViewState {

}

export const SectionListView: React.FunctionComponent<SectionListViewProps> = (props: SectionListViewProps) => {

    const { uuid, site_uuid } = useParams()
    const dispatch = useDispatch();
    const initialState: SectionListViewState = {
        page: 1,
        perPage: 5,
    };

    const [sectionListState, setSectionListState] = useState<
        SectionListViewState | undefined
    >(initialState);

    const { isSuccess, data } = useGetSectionBySiteIdQuery({
        site_uuid,
        searchText: props.searchText,
        page: sectionListState?.page,
        per_page: sectionListState?.perPage,
    });

    useEffect(() => {
        if (data && (isSuccess)) {
            const markers: Marker[] = data.result.map((section: Section) => {
                return {
                    title: section.section_id ?? '',
                    marker: {
                        lat: section.lat ?? 0,
                        lng: section.lon ?? 0
                    }
                }
            });
            dispatch(setMarkers(markers));
        }
    }, [data]);

    const handlePageChange = (
        _event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setSectionListState({
            page: value,
            perPage: initialState.perPage,
        });
    };


    return (
        <>
            {data?.result?.length ? (
                <TableContainer component={Paper} className="sitetable">
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell rowSpan={2}>Section Name</TableCell>
                                <TableCell rowSpan={2}>Last leak Detected</TableCell>
                                <TableCell rowSpan={2}>Description</TableCell>
                                <TableCell rowSpan={2}>Lat, Lon</TableCell>
                            </TableRow>
                            
                        </TableHead>
                        <TableBody>
                            {(data ? data.result : []).map((section: Section) => (
                                <TableRow key={section.section_id}>
                                    <TableCell>
                                        <Link
                                            className="site-link"
                                            to={`/customers/detail/${uuid}/site/detail/${site_uuid}/section/detail/${section.uuid}`}
                                        >
                                            {section.section_id}
                                        </Link>{" "}
                                    </TableCell>
                                    <TableCell>{section.insert_datetime}</TableCell>
                                    <TableCell>{section.Desc}</TableCell>
                                    <TableCell>
                                        {section.lat}, {section.lon}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <Box className="paginationouter">
                                        <Stack spacing={2}>
                                            {/* <Pagination count={10} color='primary' /> */}
                                            <Pagination
                                                page={data?.page ?? 1}
                                                count={data?.total_pages ?? 1}
                                                color="primary"
                                                onChange={handlePageChange}
                                            />
                                        </Stack>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            ) : <NoRecord title="Sections" />}
            {
                data?.result?.length &&
                <Box className="sectionmap">
                    <CoreMap title="Section List" />
                </Box>
            }
        </>
    )
}