import { Box, Button, Grid, Tab, Tabs } from "@mui/material";

import { PageBreadCrum } from 'components/authenticated/common/Breadcrum';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { setAlert, useGetCustomerByFieldQuery, useGetSiteByUuidQuery } from "redux-services";
import { AnemometerListView } from "../anemometer/AnemometerListView";
import Layout from "../common/Layout";
import MuiSearchBox from "../common/MuiSearchBox";
import TabPanel from "../common/TabPanel";
import EndPointListView from "../endpoint/EndpointListView";
import { SectionListView } from "../section/SectionListView";
import SiteDetailView from "./site-detail/SiteDetailView";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { BASE_URL } from "redux-services/apis/AxiosBaseQuery";
import axios from "axios";
import { now } from "lodash";
import { GatewayListView } from "../gateway/GatewayListView";
import ActionView from "../common/ActionView";
import { useDispatch } from "react-redux";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SiteTabview: React.FunctionComponent = (_props) => {
  const [value, setValue] = useState(0);
  const [endpointSearchText, setEndpointSearchText] = useState('');
  const [anemometerSearchText, setAnemometerSearchText] = useState('');

  const [sectionSearchText, setSectionSearchText] = useState('');
  const [gatewaySearchText, setGatewaySearchText] = useState('');

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  const { uuid, site_uuid } = useParams();


  const { isLoading, data: customerData } = useGetCustomerByFieldQuery({
    field: 'customer_uuid',
    value: uuid
  });

  const { isLoading: siteLoading, data: siteData } = useGetSiteByUuidQuery(site_uuid);


  const siteBreadcrum: PageBreadCrum[] = [
    {
      description: "Customer list",
      displayName: 'Customers',
      link: `/customers`
    },
    {
      description: "Customer Detail",
      displayName: customerData?.result[0]?.name ?? '',
      link: `/customers/detail/${customerData?.result[0]?.uuid}`
    },
    {
      description: "Customer Site Detail",
      displayName: siteData?.result[0].name ?? '',
      link: `/customers/${customerData?.result[0]?.uuid}/site/${site_uuid}`
    }
  ]

  const handleEndPointSearchChange = (endPointSearchText: string) => {
    setEndpointSearchText(endPointSearchText);
  }

  const handleAnemometerSearchChange = (_anemometerSearchText: string) => {
    setAnemometerSearchText(_anemometerSearchText)
  }

  const handleSectionSearchChange = (_sectionSearchText: string) => {
    setSectionSearchText(_sectionSearchText)
  }
  const handleGatewatSearchChange = (_gatwaySearchText: string) => {
    setGatewaySearchText(_gatwaySearchText)
  }

  const exportData = () => {

    
    axios({
      url: BASE_URL + '/endpointdata/exportbysite', 
      method: 'GET',
      headers: localStorage.userAccessToken? {
        'Authorization': 'Bearer ' + localStorage.userAccessToken
      }: {},
      responseType: 'blob', 
      params: {
        site_uuid: site_uuid,
        start_date: "2022-04-01T00:00:00.0000",
        end_date: "2022-07-28T00:00:00.0000",
      }
  }).then((response) => {
    console.log(response.data.size)
      if(response.data.size > 4) 
      {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', site_uuid+"-"+now()+'.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        dispatch(setAlert({
          type: 'error',
          message: "No data exists"
        }));
      }      
  })
  }

  return (
    <>
      <Layout isLoading={siteLoading || isLoading} breadcrums={siteBreadcrum}>

        <Box className="mainsection">
          <Box className="tabouter">
            <Box className='tabinnerbox' sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="tabitemouter"
              >
                <Tab label="Site Details" {...a11yProps(0)} />
                <Tab label="Endpoints" {...a11yProps(1)} />
                <Tab label="Anemometers" {...a11yProps(2)} />
                <Tab label="Sections" {...a11yProps(3)} />
                <Tab label="Gateways" {...a11yProps(4)} />
              </Tabs>
              <Box className="searchrighttab addcustomerrightpart">
                {value == 0 && (
                  <Grid className="search export-btn">
                    <Button className="btn customer-form-btn" title="Export Data" onClick={exportData}> <FileDownloadIcon /> </Button>
                    <ActionView actionClick={`/customers/detail/${uuid}/site/edit/${site_uuid}`}/>
                  </Grid>
                )}
                {value == 1 && (
                  <>
                    <MuiSearchBox searchHandler={handleEndPointSearchChange} />
                    <Grid className="search">
                      <Link className="btn customer-form-btn " to={`/customers/detail/${uuid}/site/detail/${site_uuid}/endpoint/add`}> Add Endpoint </Link>
                    </Grid>
                  </>
                )}
                {value == 2 && (
                  <>
                    <MuiSearchBox placeholder="Search" searchHandler={handleAnemometerSearchChange} />
                    <Grid className="search">
                      <Link className="btn customer-form-btn " to={`/customers/detail/${uuid}/site/detail/${site_uuid}/anemometer/add`}> Add Anemometer </Link>
                    </Grid>
                  </>
                )}
                {value == 3 && (
                  <>
                    <MuiSearchBox placeholder="Search" searchHandler={handleSectionSearchChange} />
                    <Grid className="search">
                      <Link className="btn customer-form-btn " to={`/customers/detail/${uuid}/site/detail/${site_uuid}/section/add`}> Add Section </Link>
                    </Grid>
                  </>
                )}
                {value == 4 && (
                  <>
                    <MuiSearchBox placeholder="Search" searchHandler={handleGatewatSearchChange} />
                    <Grid className="search">
                      <Link className="btn customer-form-btn " to={`/customers/detail/${uuid}/site/detail/${site_uuid}/gateway/add`}> Add Gateway </Link>
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
            <Box className="borderdiv">
              <TabPanel value={value} index={0} >
                <SiteDetailView site={siteData?.result[0]} />
              </TabPanel>
              <TabPanel value={value} index={1} >
                <EndPointListView searchText={endpointSearchText} />
              </TabPanel>
              <TabPanel value={value} index={2} >
                <AnemometerListView searchText={anemometerSearchText} />
              </TabPanel>
              <TabPanel value={value} index={3} >
                <SectionListView searchText={sectionSearchText} />
              </TabPanel>
              <TabPanel value={value} index={4} >
                <GatewayListView searchText={gatewaySearchText} />
              </TabPanel>
            </Box>
          </Box>
          
        </Box>
      </Layout >

    </>
  );
};
export default SiteTabview;
